import React, { useEffect, useRef, useState } from "react";
import Header from "../../../general-components/ui-components/Header";
import Footer from "../../../general-components/ui-components/Footer";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  InputAdornment,
  Paper,
  Rating,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { apiList, invokeApi } from "../../../../services/apiServices";
import { config } from "../../../../config/config";
import { useCookies } from "react-cookie";
import { mobileNoValidation } from "../../../../common/common";
import { toast } from "react-toastify";

const TrainingInstituteDetails = () => {
  const { trainingInstituteId } = useParams();
  const [cookies] = useCookies();
  const [expandedCards, setExpandedCards] = useState({});
  const [instituteFetching, setInstituteFetching] = useState(true);
  const [trainingInstitute, setTrainingInstitute] = useState(null);

  const [activeTab, setActiveTab] = useState(0);
  const overviewRef = useRef(null);
  const benefitsAndFeatures = useRef(null);
  const basicDetailsRef = useRef(null);
  const galleryRef = useRef(null);
  const facultyRef = useRef(null);
  const courseDetailsRef = useRef(null);

  const [openLeadModal, setLeadModalOpen] = useState(false);

  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState(false);
  const [fullNameHelperText, setFullNameHelperText] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [mobileNumberHelperText, setMobileNumberHelperText] = useState("");
  const mobileNumberValidate = mobileNoValidation(mobileNumber);

  const [emailId, setEmailId] = useState("");
  const [emailIdError, setEmailIdError] = useState(false);
  const [emailIdHelperText, setEmailIdHelperText] = useState("");

  const [trainingInstituteCourseList, setTrainingInstituteCourseList] =
    useState([]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    switch (newValue) {
      case 0:
        if (overviewRef.current) {
          overviewRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case 5:
        if (benefitsAndFeatures.current) {
          benefitsAndFeatures.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case 2:
        if (basicDetailsRef.current) {
          basicDetailsRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case 3:
        if (galleryRef.current) {
          galleryRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case 4:
        if (facultyRef.current) {
          facultyRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case 1:
        if (courseDetailsRef.current) {
          courseDetailsRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      default:
        break;
    }
  };

  const handleToggleExpand = (id) => {
    setExpandedCards((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle the expansion state for the clicked card
    }));
  };

  const handleClose = () => {
    setLeadModalOpen((prev) => !prev);
  };

  const saveLead = async (e) => {
    e.preventDefault();

    if (mobileNumberValidate) {
      let validationError = false;

      if (fullName === "") {
        setFullNameHelperText("Please enter your Full Name");
        setFullNameError(true);
        validationError = true;
      }
      if (emailId === "") {
        setEmailIdHelperText("Please enter your Email Id");
        setEmailIdError(true);
        validationError = true;
      }
      if (!validationError) {
        let params = {
          studentName: fullName,
          mobileNumber: mobileNumber,
          email: emailId,
          trainingInstituteCourseList,
          trainingInstituteId: trainingInstituteId,
        };
        let response = await invokeApi(
          config.getMyCollege + apiList.saveLead,
          params,
          cookies
        );
        if (response.status >= 200 && response.status < 300) {
          if (response.data.responseCode === "200") {
            handleClose();
            toast.success("Lead Created Successfully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setFullName("");
            setFullNameHelperText("");
            setFullNameError(false);

            setMobileNumber("");
            setMobileNumberError(false);
            setMobileNumberHelperText("");

            setEmailId("");
            setEmailIdError(false);
            setEmailIdHelperText("");

            // setSelectedEduType("");
          } else {
            alert(
              "Something went wrong while saving the Leading Details. Please try again later!"
            );
          }
        } else {
          alert(
            "Something went wrong while saving the Leading Details. Please try again later!!"
          );
        }
      }
    } else {
      setMobileNumberHelperText("Please Enter Valid Mobile Number");
      setMobileNumberError(true);
      if (fullName === "") {
        setFullNameHelperText("Please enter your Full Name");
        setFullNameError(true);
      }
      if (emailId === "") {
        setEmailIdHelperText("Please enter your Email Id");
        setEmailIdError(true);
      }
    }
  };

  useEffect(() => {
    const getTrainingInstitute = async () => {
      let params = {
        id: trainingInstituteId,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getInstitute,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setTrainingInstitute(response.data.trainingInstitute);
        } else {
          alert(
            "Something went wrong while getting the training institute Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the training institute Details. Please try again later!!"
        );
      }
    };
    if (instituteFetching) {
      getTrainingInstitute();
      setInstituteFetching(false);
    }
  }, [cookies, instituteFetching]);
  return (
    <>
      <Header />
      <Box sx={{ backgroundColor: "#bdc3c7", py: 5 }}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item md={4} component={Paper} sx={{ p: 2 }}>
              <img
                src={trainingInstitute?.logoUrl}
                alt={trainingInstitute?.name}
                // height="300px"
                width="100%"
                style={{ objectFit: "cover" }}
              />
            </Grid>
            <Grid item md={8} component={Paper} sx={{ p: 2 }}>
              <Typography variant="h5" component={"h1"}>
                {trainingInstitute?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  display: "flex",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                5.0
                <Rating
                  name="read-only"
                  sx={{ fontSize: { md: "20px", xs: "8px" } }}
                  value={5}
                  readOnly
                />
                (542 Reviews)
              </Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item sm={4}>
                  <Typography variant="overline">Total Courses</Typography>
                  <Typography variant="body1">
                    {trainingInstitute?.courseDetails.length}
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <Typography variant="overline">Faculties</Typography>
                  <Typography variant="body1">
                    {trainingInstitute?.faculties.length}
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <Typography variant="overline">Since</Typography>
                  <Typography variant="body1">
                    {trainingInstitute?.establishedYear}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button fullWidth variant="contained" onClick={handleClose}>
                    Apply Now
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Tabs Section */}
          <Box
            sx={{
              bgcolor: "white",
              borderRadius: 2,
              boxShadow: 1,
              position: "sticky",
              top: 0,
              zIndex: 1,
              marginTop: 2,
            }}
          >
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              sx={{
                "& .MuiTab-root": {
                  borderRadius: 2,
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                  },
                  "&.Mui-selected": {
                    color: "blue",
                  },
                },
              }}
            >
              <Tab label="Overview" />
              <Tab label="Courses And Fee" />
              <Tab label="Benefits & Features" />
              <Tab label="Basic Details" />
              {trainingInstitute?.trainingInstituteGallery?.length > 0 && (
                <Tab label="Gallery" />
              )}
              <Tab label="Faculty" />
            </Tabs>
          </Box>

          {/* Tab Content */}
          <Box sx={{ p: 2, bgcolor: "white", mt: 2 }}>
            <div ref={overviewRef} style={{ paddingTop: "60px" }}>
              {" "}
              {/* Adjust padding as needed */}
              <Typography variant="h6">
                About {trainingInstitute?.name}
              </Typography>
              <Typography variant="body1">
                <Box
                  dangerouslySetInnerHTML={{
                    __html: trainingInstitute?.description,
                  }}
                />
              </Typography>
            </div>
            <div ref={courseDetailsRef} style={{ paddingTop: "60px" }}>
              {" "}
              {/* Adjust padding as needed */}
              <Typography variant="h6">Course And Fees Details</Typography>
              <Grid container spacing={3} style={{ marginTop: "20px" }}>
                {trainingInstitute?.courseDetails.map((course) => (
                  <Grid item xs={12} sm={6} md={4} key={course.id}>
                    <Card
                      style={{
                        height: expandedCards[course.id] ? "auto" : "350px", // Set fixed height
                        transition: "height 0.3s ease", // Smooth height transition
                        display: "flex", // Use flex to manage card content
                        flexDirection: "column", // Stack content vertically
                        position: "relative",
                      }}
                    >
                      <CardContent style={{ flex: 1 }}>
                        {" "}
                        {/* Allow CardContent to grow */}
                        <Typography variant="h5">
                          {course.courseName}
                        </Typography>
                        <Typography color="textSecondary">
                          Created By: {course.createdBy} on{" "}
                          {new Date(course.createdDate).toLocaleDateString()}
                        </Typography>
                        {/* Offline Course Details */}
                        <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                          Offline Course
                        </Typography>
                        <Divider />
                        <Typography variant="body2">
                          <strong>Fee:</strong> {course.offlineFee} <br />
                          <strong>Duration:</strong> {course.offlineDuration}{" "}
                          <br />
                          <strong>Features:</strong>
                        </Typography>
                        <ul style={{ listStyle: "none" }}>
                          {course.offlineFeatures
                            .split(",")
                            .map((feature, index) => (
                              <li key={index}>
                                {index + 1}. {feature.trim()}
                              </li>
                            ))}
                        </ul>
                        {/* Online Course Details */}
                        <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                          Online Course
                        </Typography>
                        <Divider />
                        <Typography variant="body2">
                          <strong>Fee:</strong> {course.onlineFee} <br />
                          <strong>Duration:</strong> {course.onlineDuration}{" "}
                          <br />
                          <strong>Features:</strong>
                        </Typography>
                        <ul style={{ listStyle: "none" }}>
                          {course.onlineFeatures
                            .split(",")
                            .map((feature, index) => (
                              <li key={index}>
                                {index + 1}. {feature.trim()}
                              </li>
                            ))}
                        </ul>
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleClose();
                            setTrainingInstituteCourseList((prev) => [
                              ...prev,
                              course.courseName,
                            ]);
                          }}
                        >
                          Apply
                        </Button>
                      </CardContent>
                      {/* Show More Button */}
                      <Button
                        variant="text" // Use a contained button for better visibility
                        onClick={() => handleToggleExpand(course.id)}
                        sx={{
                          mt: 2,
                          mb: 2,
                          alignSelf: "center",
                          position: "absolute",
                          bottom: "0px",
                          right: "10px",
                        }} // Center the button
                        size="small"
                      >
                        {expandedCards[course.id] ? "Show Less" : "Show More"}
                      </Button>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
            <div ref={benefitsAndFeatures} style={{ paddingTop: "60px" }}>
              {/* Benefits and Features content in a table */}
              <h2>Benefits and Features</h2>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Sl No.</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Benefits</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Sl No.</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Features</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.from({
                    length: Math.max(
                      trainingInstitute?.benefits
                        .split(",")
                        .map((item) => item.trim()).length,
                      trainingInstitute?.features
                        .split(",")
                        .map((item) => item.trim()).length
                    ),
                  }).map((_, index) => (
                    <TableRow key={index}>
                      {/* Benefits */}
                      <TableCell>
                        {index <
                        trainingInstitute?.benefits
                          .split(",")
                          .map((item) => item.trim()).length
                          ? index + 1
                          : null}
                      </TableCell>
                      <TableCell>
                        {index <
                        trainingInstitute?.benefits
                          .split(",")
                          .map((item) => item.trim()).length
                          ? trainingInstitute?.benefits
                              .split(",")
                              .map((item) => item.trim())[index]
                          : null}
                      </TableCell>

                      {/* Features */}
                      <TableCell>
                        {index <
                        trainingInstitute?.features
                          .split(",")
                          .map((item) => item.trim()).length
                          ? index + 1
                          : null}
                      </TableCell>
                      <TableCell>
                        {index <
                        trainingInstitute?.features
                          .split(",")
                          .map((item) => item.trim()).length
                          ? trainingInstitute?.features
                              .split(",")
                              .map((item) => item.trim())[index]
                          : null}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>

            <div ref={basicDetailsRef} style={{ paddingTop: "60px" }}>
              <Typography variant="h6">Basic Details</Typography>

              <Typography variant="body1" sx={{ mt: 1 }}>
                <strong>Address:</strong> {trainingInstitute?.address}
              </Typography>
              <Typography variant="body1">
                <strong>City:</strong> {trainingInstitute?.city}
              </Typography>
              <Typography variant="body1">
                <strong>Pincode:</strong> {trainingInstitute?.pincode}
              </Typography>
              <Typography variant="body1">
                <strong>State:</strong> {trainingInstitute?.state}
              </Typography>
              <Typography variant="body1">
                <strong>Country:</strong> {trainingInstitute?.country}
              </Typography>
              <Typography variant="body1">
                <strong>Since:</strong> {trainingInstitute?.establishedYear}
              </Typography>
            </div>

            {trainingInstitute?.trainingInstituteGallery?.length > 0 && (
              <div ref={galleryRef} style={{ paddingTop: "60px" }}>
                {" "}
                {/* Adjust padding as needed */}
                <Typography variant="h6">School Gallery Images</Typography>
                <ImageList
                  sx={{ width: "100%", height: 450 }}
                  cols={3}
                  rowHeight={164}
                >
                  {trainingInstitute?.trainingInstituteGallery?.map(
                    (item, index) => (
                      <ImageListItem key={index}>
                        <img
                          srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          src={`${item}?w=164&h=164&fit=crop&auto=format`}
                          alt="gallery image"
                          loading="lazy"
                        />
                      </ImageListItem>
                    )
                  )}
                </ImageList>
              </div>
            )}
            <div ref={facultyRef} style={{ paddingTop: "60px" }}>
              {" "}
              {/* Adjust padding as needed */}
              <Typography variant="h6">Training Insitute Faculties</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Faculty Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Profession</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Experience</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Description</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trainingInstitute?.faculties.map((faculty, index) => (
                    <TableRow key={index}>
                      <TableCell>{faculty.name}</TableCell>
                      <TableCell>{faculty.profession}</TableCell>
                      <TableCell>{faculty.experience}</TableCell>
                      <TableCell>
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: faculty?.description,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Box>
        </Container>
      </Box>
      <Footer />
      <Dialog open={openLeadModal} onClose={handleClose}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Apply
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="dense"
                label="Name"
                value={fullName}
                style={{ marginTop: { xs: "10px" } }}
                onChange={(ev) => {
                  setFullName(ev.target.value);
                  setFullNameError(false);
                  setFullNameHelperText("");
                }}
                helperText={fullNameHelperText}
                error={fullNameError}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="normal"
                label="Mobile Number"
                value={mobileNumber}
                onChange={(ev) => {
                  setMobileNumber(ev.target.value);
                  setMobileNumberError(false);
                  setMobileNumberHelperText("");
                }}
                helperText={mobileNumberHelperText}
                error={mobileNumberError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <>
                        {/* flag and country code */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "0px",
                            gap: "5px",
                          }}
                        >
                          <Box
                            component={"img"}
                            sx={{ width: "24px", height: "24px" }}
                            alt="india"
                            src="/media/svg/indianflag.svg"
                          />
                          <Typography
                            variant="bodyparagraph"
                            sx={{
                              color: "text.primary",
                            }}
                          >
                            +91
                          </Typography>
                        </Box>
                        {/* vertical line */}
                        <Box
                          sx={{
                            width: { md: "26px", xs: "20px" },
                            height: "0px",
                            border: "0.5px solid #AAACAE",
                            transform: "rotate(90deg)",
                          }}
                        />
                      </>
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 10 }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="normal"
                label="Email Id"
                value={emailId}
                onChange={(ev) => {
                  setEmailId(ev.target.value);
                  setEmailIdError(false);
                  setEmailIdHelperText("");
                }}
                helperText={emailIdHelperText}
                error={emailIdError}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="edu-type-autocomplete"
                options={trainingInstitute?.courses}
                getOptionLabel={(option) => option}
                value={trainingInstituteCourseList}
                fullWidth
                onChange={(event, newValue) => {
                  setTrainingInstituteCourseList(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Courses"
                    fullWidth
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", my: 2 }}
            >
              <Button type="submit" onClick={saveLead} variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TrainingInstituteDetails;
