import React, { useEffect, useState } from "react";
import {
  AppBar,
  Typography,
  Button,
  Stack,
  useMediaQuery,
  useTheme,
  ButtonGroup,
  ListItemIcon,
  MenuItem,
  Divider,
  Avatar,
  IconButton,
  CssBaseline,
  Menu,
  Drawer,
  List,
  Box,
  Grid,
  ListItemButton,
  ListItemText,
  ListItem,
  Input,
  InputAdornment,
  Tabs,
  Tab,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUser } from "../../../global/redux/action";
import SearchIcon from "@mui/icons-material/Search";
import { useCookies } from "react-cookie";
import { config } from "../../../config/config";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SignUp from "../../auth/signup/SignUp";
import Login from "../../auth/login/Login";
import { Logout, PersonAdd } from "@mui/icons-material";
import MenuItems from "./MenuItems";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { topCities, otherCities } from "./MenuItems";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { apiList, invokeApi } from "../../../services/apiServices";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const TabBox = {
  // border: { xs: "none", md: " ridge " },
  borderBottom: "1px solid #F5F5F5",
  shadows: "gray",
  backgroundColor: { xs: "#F5F5F5", md: "white" },
  justifyContent: "Start",
  width: { xs: "100%" },
  minHeight: { xs: "30px", md: "100%" },
  height: { xs: "40px", md: "55px" },
  textTransform: "capitalize",
};

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("lg"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [cookies, setCookie] = useCookies();
  const globalState = useSelector((state) => state);
  const [programValue, setProgramValue] = useState("college");
  const { userData, userError, logout } = globalState.userReducer;
  const [addressDrawer, setAddressDrawer] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [filteredTopCities, setFilteredTopCities] = useState([]);
  const [filteredOtherCities, setFilteredOtherCities] = useState([]);
  const [programFetching, setProgramFetching] = useState(true);
  const [programList, setProgramList] = useState([]);
  const [activeProgram, setActiveProgram] = useState(null);
  const [courseList, setCourseList] = useState([]);
  const [specializationList, setSpecializationList] = useState([]);
  const [collegeSubMenuProgramDrawer, setCollegeSubMenuProgramDrawer] =
    useState(false);
  const [collegeSubMenuCourseDrawer, setCollegeSubMenuCourseDrawer] =
    useState(false);
  const [
    collegeSubMenuSpecializationDrawer,
    setCollegeSubMenuSpecializationDrawer,
  ] = useState(false);
  const [schoolProgramDrawer, setSchoolProgramDrawer] = useState(false);
  const [trainingProgramDrawer, setTrainingProgramDrawer] = useState(false);

  const [
    isTrainingInstituteProgramFetching,
    setIsTrainingInstituteProgramFetching,
  ] = useState(true);
  const [trainingInstituteProgramList, setTrainingInstituteProgramList] =
    useState([]);

  const handleCollegeSubMenuProgramDrawer = () => {
    setCollegeSubMenuProgramDrawer(!collegeSubMenuProgramDrawer);
    setOpenDrawer(false);
  };

  const handleCollegeSubMenuCourseDrawer = () => {
    setCollegeSubMenuCourseDrawer(!collegeSubMenuCourseDrawer);
    setCollegeSubMenuProgramDrawer(false);
    setOpenDrawer(false);
  };
  const handleCollegeSubMenuSpecializationDrawer = () => {
    setCollegeSubMenuSpecializationDrawer(!collegeSubMenuSpecializationDrawer);
    setCollegeSubMenuProgramDrawer(false);
    setCollegeSubMenuCourseDrawer(false);
    setOpenDrawer(false);
  };

  const handleSchoolProgramDrawer = () => {
    setSchoolProgramDrawer(!schoolProgramDrawer);
    setOpenDrawer(false);
  };
  const handleTrainingProgramDrawer = () => {
    setTrainingProgramDrawer(!trainingProgramDrawer);
    setOpenDrawer(false);
  };

  const handleIsSignUpModal = () => {
    setOpenSignUp(!openSignUp);
  };
  const handleIsLoginModal = () => {
    setOpenLogin(!openLogin);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openProfileMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddressDrawer = () => {
    setAddressDrawer((editing) => !editing);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveProgram(event.target.innerText.toLowerCase());
    setValue(newValue);
  };

  function handleSetProgramCookie(id) {
    const updatedCookies = {
      ...cookies[config.filterCookie],
      program: id,
    };
    setCookie(config.filterCookie, JSON.stringify(updatedCookies), {
      path: "/",
      maxAge: 3000000,
      sameSite: "strict",
    });
  }

  const [anchorElCity, setAnchorElCity] = useState(null);
  function handleCity(event) {
    if (anchorElCity !== event.currentTarget) {
      setAnchorElCity(event.currentTarget);
    }
  }
  function handleCloseCity() {
    setAnchorElCity(null);
  }

  const [anchorElColleges, setAnchorElColleges] = useState(null);
  function handleColleges(event) {
    if (anchorElColleges !== event.currentTarget) {
      setAnchorElColleges(event.currentTarget);
    }
  }
  function handleCloseColleges() {
    setAnchorElColleges(null);
  }

  const [anchorElSchools, setAnchorElSchools] = useState(null);
  function handleSchools(event) {
    if (anchorElSchools !== event.currentTarget) {
      setAnchorElSchools(event.currentTarget);
    }
  }
  function handleCloseSchools() {
    setAnchorElSchools(null);
  }

  const [anchorElTraining, setAnchorElTraining] = useState(null);
  function handleTraining(event) {
    if (anchorElTraining !== event.currentTarget) {
      setAnchorElTraining(event.currentTarget);
    }
  }
  function handleCloseTraining() {
    setAnchorElTraining(null);
  }

  useEffect(() => {
    // Filter top cities
    const filteredTop = topCities.filter((city) =>
      city.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredTopCities(filteredTop);

    // Filter other cities
    const filteredOther = otherCities.filter((cityName) =>
      cityName.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredOtherCities(filteredOther);
  }, [searchText, topCities, otherCities]);

  // On error when get user api is called
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  // Generate sessionId, if not exists
  useEffect(() => {
    if (
      !cookies[config.sessionCookie] ||
      !cookies[config.sessionCookie].sessionId
    ) {
      let sessionId = uuidv4();
      setCookie(
        config.sessionCookie,
        JSON.stringify({
          sessionId: sessionId,
        }),
        { path: "/", maxAge: 3000000, sameSite: "strict" }
      );
    }
  }, [cookies, setCookie]);

  const dashboard = () => {
    if (userData?.users.passwordChangeOnLogin === "Yes") {
      navigate("/setPassword");
    } else if (userData?.users.passwordChangeOnLogin === "No") {
      navigate("/dashboard");
    }
  };

  //  Address selection; if user not having address, try to fetch current location
  useEffect(() => {
    const getAddressCurrentLocation = (lat, lng) => {
      let url =
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        lat +
        "," +
        lng +
        "&key=" +
        config.googleMapKey;

      fetch(url, {
        method: "get",
      }).then((response) => {
        response.json().then((res) => {
          if (res.results.length > 0) {
            let data = res.results[0].address_components;
            if (data.length > 0) {
              let cityVal = "";
              let stateVal = "";
              data.forEach((element) => {
                if (element.types.includes("locality")) {
                  cityVal = element.long_name;
                } else if (
                  element.types.includes("administrative_area_level_1")
                ) {
                  stateVal = element.long_name;
                }
              });
              setCookie(
                config.preferencesCookie,
                JSON.stringify({
                  city: cityVal || "Bengaluru",
                  state: stateVal,
                }),
                { path: "/", maxAge: 3000000, sameSite: "strict" }
              );
              console.log(cookies);
            }
          }
        });
      });
    };

    const getCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            getAddressCurrentLocation(
              position.coords.latitude,
              position.coords.longitude
            );
          },
          // failure call back
          (error) => {
            getAddressCurrentLocation(
              config.defaultLocation.latitude,
              config.defaultLocation.longitude
            );
            console.log(error);
          }
        );
      } else {
        getAddressCurrentLocation(
          config.defaultLocation.latitude,
          config.defaultLocation.longitude
        );
      }
    };

    if (
      !cookies[config.preferencesCookie] ||
      !cookies[config.preferencesCookie].city
    ) {
      getCurrentLocation();
    }
  }, [cookies]);

  useEffect(() => {
    const getAllPrograms = async () => {
      let params = {
        status: "Active",
        instituteType: programValue,
      };

      let response = await invokeApi(
        config.getMyCollege + apiList.getPrograms,
        params,
        cookies
      );

      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          const firstProgram = response.data?.programs[0]?.programName;
          setProgramList(response.data.programs);
          setActiveProgram(firstProgram);
        } else {
          alert(
            "Something went wrong while getting the Program Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the Program Details. Please try again later!!"
        );
      }
    };

    // Check if programs are still fetching
    if (programFetching) {
      getAllPrograms();
      setProgramFetching(false);
    }
  }, [cookies, programFetching]);

  useEffect(() => {
    const getAllCourseName = async () => {
      let params = {
        status: "Active",
        programName: activeProgram,
      };
      console.log("param", params);
      let response = await invokeApi(
        config.getMyCollege + apiList.getCourses,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCourseList(response.data.courses);
        } else {
          alert(
            "Something went wrong while getting the course list Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the course list Details. Please try again later!!"
        );
      }
    };
    if (activeProgram) {
      getAllCourseName();
      // getAllSpecializationName();
    }
  }, [cookies, activeProgram]);

  const getAllSpecializationName = async (id) => {
    let params = {
      status: "Active",
      courseId: id,
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.getSpecialization,
      params,
      cookies
    );
    if (response?.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        setSpecializationList(response.data.specialization);
      } else {
        alert(
          "Something went wrong while getting the specialization list Details. Please try again later!"
        );
      }
    } else {
      alert(
        "Something went wrong while getting the specialization list Details. Please try again later!!"
      );
    }
  };

  function handleSetCourseCookie(id) {
    const updatedCookies = {
      ...cookies[config.filterCookie],
      course: id,
    };
    setCookie(config.filterCookie, JSON.stringify(updatedCookies), {
      path: "/",
      maxAge: 3000000,
      sameSite: "strict",
    });
    getAllSpecializationName(id);
  }

  useEffect(() => {
    if (!cookies[config.filterCookie]) {
      setCookie(
        config.filterCookie,
        JSON.stringify({
          university: null,
          program: null,
          programType: null,
          course: null,
          exam: null,
          amenity: null,
          state: cookies[config.preferencesCookie]?.state || null,
          city: cookies[config.preferencesCookie]?.city || null,
        }),
        {
          path: "/",
          maxAge: 3000000,
          sameSite: "strict",
        }
      );
    }
  }, [cookies]);

  useEffect(() => {
    const getAllTrainingInstituteProgram = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getTrainingInstitutePrograms,
        params,
        cookies
      );

      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setTrainingInstituteProgramList(
            response.data.trainingInstitutePrograms
          );
        } else {
          alert(
            "Something went wrong while getting the training institute program Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the training institute program Details. Please try again later!!"
        );
      }
    };
    if (isTrainingInstituteProgramFetching) {
      getAllTrainingInstituteProgram();
      setIsTrainingInstituteProgramFetching(false);
    }
  }, [cookies, isTrainingInstituteProgramFetching]);

  useEffect(() => {
    if (logout) {
      navigate("/logout");
    }
  }, [logout]);

  return (
    <>
      <CssBaseline />
      <AppBar
        position="static"
        sx={{
          textAlign: "center",
          width: "100%",
          py: 1,
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, rgba(52, 68, 73, 0.20) 0%, rgba(0, 0, 0, 0.00) 46.64%, rgba(14, 194, 251, 0.20) 85.38%), #C8EBFE",
        }}
      >
        <Typography variant="subtitle2" sx={{ fontWeight: "strong" }}>
          {isMatch
            ? "Start Your College Discovery... "
            : `Start Your College Discovery... Top Colleges in India based on ${new Date().getFullYear()} Ranking`}
        </Typography>
      </AppBar>

      <AppBar
        sx={{
          width: "100%",
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, rgba(52, 68, 73, 0.20) 0%, rgba(0, 0, 0, 0.00) 46.64%, rgba(14, 194, 251, 0.20) 85.38%), #162E7C",
        }}
        position="static"
      >
        {isMatch ? (
          <>
            <Box sx={{ display: "flex" }}>
              <IconButton
                sx={{ color: "white" }}
                onClick={() => {
                  setOpenDrawer(true);
                }}
              >
                <MenuIcon />
              </IconButton>
              <Stack
                sx={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "7px",
                  marginBottom: "7px",
                }}
              >
                <Link to={"/"}>
                  <img src="/logo-1.png" alt="logo" height="40px" />
                </Link>
              </Stack>
            </Box>
          </>
        ) : (
          <>
            <Grid container sx={{ py: 1, px: 3 }}>
              <Grid
                item
                md={2}
                onClick={() => {
                  navigate("/");
                }}
                sx={{ cursor: "pointer" }}
              >
                <img src="/logo-1.png" alt="logo" height="40px" />
              </Grid>
              <Grid
                item
                md={userData?.users.roles ? 9 : 8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                {/* <MenuItems /> */}
                <Button
                  variant="text"
                  sx={{ color: "whitesmoke", textTransform: "capitalize" }}
                  onClick={() => navigate("/")}
                >
                  Home
                </Button>

                {/* <MenuItems
                  name={cookies[config.preferencesCookie]?.city || "Bengaluru"}
                /> */}
                <Button
                  variant="text"
                  sx={{ color: "whitesmoke", textTransform: "capitalize" }}
                  aria-owns={anchorEl ? "simple-menu" : undefined}
                  aria-haspopup="true"
                  onClick={handleCity}
                  onMouseOver={handleCity}
                >
                  {cookies[config.preferencesCookie]?.city || "Bengaluru"}
                  {anchorElCity === null ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowDropUpIcon />
                  )}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorElCity}
                  open={Boolean(anchorElCity)}
                  getContentAnchorElCity={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center", // Center the menu horizontally
                  }}
                  transformOrigin={{ horizontal: "center", vertical: "top" }}
                  onClose={handleCloseCity}
                  MenuListProps={{ onMouseLeave: handleCloseCity }}
                >
                  <List>
                    <ListItem>
                      <Input
                        placeholder="Search"
                        value={searchText}
                        onChange={handleSearchChange}
                        fullWidth
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    </ListItem>
                    <ListItem sx={{ cursor: "default", fontWeight: "bold" }}>
                      <ListItemText
                        primary="Top Cities"
                        sx={{
                          fontSize: "13px",
                          textDecoration: "underline",
                        }}
                      />
                    </ListItem>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        px: "15px",
                        columnGap: "10px",
                      }}
                    >
                      {filteredTopCities?.map((cities, index) => (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid gray",
                            borderRadius: "5px",
                            my: "10px",
                            p: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setCookie(
                              config.preferencesCookie,
                              JSON.stringify({
                                ...cookies[config.preferencesCookie],
                                city: cities.name,
                              }),
                              {
                                path: "/",
                                maxAge: 3000000,
                                sameSite: "strict",
                              }
                            );
                            handleCloseCity();
                          }}
                          key={index}
                        >
                          <img src={cities.svg} height="30px" alt="cities" />
                          <Typography
                            variant="caption"
                            sx={{ "&:hover": { color: "blue" } }}
                          >
                            {cities.name}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    <ListItem sx={{ cursor: "default", fontWeight: "bold" }}>
                      <ListItemText
                        primary="Other Cities"
                        sx={{
                          fontSize: "13px",
                          textDecoration: "underline",
                        }}
                      />
                    </ListItem>
                    {filteredOtherCities?.map((subitem, subIndex) => (
                      <ListItem
                        key={subIndex}
                        button
                        onClick={() => {
                          setCookie(
                            config.preferencesCookie,
                            JSON.stringify({
                              ...cookies[config.preferencesCookie],
                              city: subitem,
                            }),
                            {
                              path: "/",
                              maxAge: 3000000,
                              sameSite: "strict",
                            }
                          );
                          handleCloseCity();
                        }}
                      >
                        <ListItemText primary={subitem} />
                      </ListItem>
                    ))}
                  </List>
                </Menu>

                {/* <MenuItems name="Colleges" /> */}
                <Button
                  variant="text"
                  sx={{ color: "whitesmoke", textTransform: "capitalize" }}
                  aria-owns={anchorEl ? "simple-menu" : undefined}
                  aria-haspopup="true"
                  onClick={handleColleges}
                  onMouseOver={handleColleges}
                >
                  Colleges
                  {anchorEl === null ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowDropUpIcon />
                  )}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorElColleges={anchorElColleges}
                  open={Boolean(anchorElColleges)}
                  getContentAnchorElColleges={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center", // Center the menu horizontally
                  }}
                  transformOrigin={{ horizontal: "center", vertical: "top" }}
                  onClose={handleCloseColleges}
                  MenuListProps={{ onMouseLeave: handleCloseColleges }}
                >
                  <Box
                    sx={{
                      minWidth: "800px",
                      minHeight: "400px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={4}
                        sx={{
                          height: "100%",
                          overflowY: "auto",
                        }}
                      >
                        <Tabs
                          orientation="vertical"
                          value={value}
                          onChange={handleChange}
                          aria-label="Vertical tabs example"
                          sx={{
                            borderColor: "divider",
                            boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.25);",
                            color: "#5B5B5B",
                            paddingBottom: "1px",
                            overflowY: "auto",
                          }}
                        >
                          {programList.map((item, index) => (
                            <Tab
                              icon={
                                <img
                                  src="/media/svg/bachelor.svg"
                                  alt="degree icon"
                                  width="25px"
                                />
                              }
                              onClick={handleSetProgramCookie.bind(
                                this,
                                item.id
                              )}
                              key={index}
                              iconPosition="start"
                              label={item.programName}
                              sx={TabBox}
                            />
                          ))}
                        </Tabs>
                      </Grid>
                      <Grid item xs={8}>
                        <TabPanel value={value} index={value}>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "repeat(2, 1fr)",
                              padding: "30px",
                              gridGap: "10px",
                            }}
                          >
                            <Box>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  textTransform: "uppercase",
                                }}
                              >
                                Colleges By Course
                              </Typography>
                              <List sx={{ fontSize: "14px" }}>
                                {courseList?.slice(0, 10).map((item, index) => (
                                  <ListItem key={index}>
                                    <Typography
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        textDecoration: "none",
                                        color:
                                          cookies[config.filterCookie]
                                            ?.course === item.id
                                            ? "#007bff"
                                            : "#7f8c8d",
                                        cursor: "pointer",
                                        justifyContent: "space-between",
                                        width: "100%",
                                      }}
                                      onClick={handleSetCourseCookie.bind(
                                        this,
                                        item.id
                                      )}
                                    >
                                      {item.courseName}
                                      <ArrowForwardIcon />
                                    </Typography>
                                  </ListItem>
                                ))}
                                <ListItem>
                                  <Link
                                    to="/colleges/college"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      textDecoration: "none",
                                      color: "#95a5a6",
                                    }}
                                  >
                                    <ListItemText primary="View All" />
                                    <ListItemIcon>
                                      <ChevronRightIcon />
                                    </ListItemIcon>
                                  </Link>
                                </ListItem>
                              </List>
                            </Box>
                            {specializationList?.length > 0 && (
                              <Box>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  Colleges By Specialization
                                </Typography>
                                <List sx={{ fontSize: "14px" }}>
                                  {specializationList.map((item, index) => (
                                    <ListItem key={index}>
                                      <Link
                                        to="/colleges/college"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          textDecoration: "none",
                                          color: "#007bff", // Blue color for list items
                                        }}
                                      >
                                        {item.specialization}
                                      </Link>
                                    </ListItem>
                                  ))}
                                  <ListItem>
                                    <Link
                                      to="/colleges/college"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        textDecoration: "none",
                                        color: "#95a5a6",
                                      }}
                                    >
                                      <ListItemText primary="View All" />
                                      <ListItemIcon>
                                        <ChevronRightIcon />
                                      </ListItemIcon>
                                    </Link>
                                  </ListItem>
                                </List>
                              </Box>
                            )}
                          </Box>
                          <Box sx={{ p: 1 }}>
                            <Button
                              variant="outlined"
                              sx={{ position: "fixed", width: "34%" }}
                            >
                              Result
                            </Button>
                          </Box>
                        </TabPanel>
                      </Grid>
                    </Grid>
                  </Box>
                </Menu>

                {/* <MenuItems name="Schools" /> */}
                <Button
                  variant="text"
                  sx={{ color: "whitesmoke", textTransform: "capitalize" }}
                  aria-owns={anchorEl ? "simple-menu" : undefined}
                  aria-haspopup="true"
                  onClick={handleSchools}
                  onMouseOver={handleSchools}
                >
                  Schools
                  {anchorElSchools === null ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowDropUpIcon />
                  )}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorElSchools={anchorElSchools}
                  open={Boolean(anchorElSchools)}
                  getContentAnchorElSchools={null}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center", // Center the menu horizontally
                  }}
                  transformOrigin={{ horizontal: "center", vertical: "top" }}
                  onClose={handleCloseSchools}
                  MenuListProps={{ onMouseLeave: handleCloseSchools }}
                  sx={{ mt: { md: 8 } }}
                >
                  <Box sx={{ minWidth: "200px" }}>
                    <List>
                      <ListItem
                        button
                        onClick={() => navigate("/schools/cbse")}
                      >
                        CBSE
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => navigate("/schools/igcse")}
                      >
                        IGCSE
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => navigate("/schools/state-board")}
                      >
                        State Board
                      </ListItem>
                      <ListItem button onClick={() => navigate("/schools/ib")}>
                        IB
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => navigate("/schools/nios")}
                      >
                        NIOS
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => navigate("/schools/aissce")}
                      >
                        AISSCE
                      </ListItem>
                    </List>
                  </Box>
                </Menu>

                {/* <MenuItems name="Training Institutes" /> */}
                <Button
                  variant="text"
                  sx={{ color: "whitesmoke", textTransform: "capitalize" }}
                  aria-owns={anchorEl ? "simple-menu" : undefined}
                  aria-haspopup="true"
                  onClick={handleTraining}
                  onMouseOver={handleTraining}
                >
                  Training Institutes
                  {anchorEl === null ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowDropUpIcon />
                  )}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorElColleges={anchorElTraining}
                  open={Boolean(anchorElTraining)}
                  getContentAnchorElTraining={null}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center", // Center the menu horizontally
                  }}
                  transformOrigin={{ horizontal: "center", vertical: "top" }}
                  onClose={handleCloseTraining}
                  MenuListProps={{ onMouseLeave: handleCloseTraining }}
                  sx={{ mt: { md: 8 }, ml: { md: 20 } }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#ced6e0",
                      padding: "30px 40px",
                    }}
                    className="border"
                  >
                    {trainingInstituteProgramList.map((item, index) => (
                      <MenuItem
                        key={index}
                        onClick={() =>
                          navigate(
                            `/training-institutes?program=${item.trainingInstituteProgram}`
                          )
                        }
                      >
                        <ListItemIcon style={{ minWidth: "20px" }}>
                          <FiberManualRecordIcon style={{ fontSize: 12 }} />{" "}
                          {/* Dot icon */}
                        </ListItemIcon>
                        <Typography variant="inherit" sx={{ fontSize: "14px" }}>
                          {item.trainingInstituteProgram}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Box>
                </Menu>
                <Button
                  variant="text"
                  sx={{ color: "whitesmoke", textTransform: "capitalize" }}
                  onClick={() => navigate("/about-us")}
                >
                  About Us
                </Button>
                <Button
                  variant="text"
                  sx={{ color: "whitesmoke", textTransform: "capitalize" }}
                  onClick={() => navigate("/contact-us")}
                >
                  Contact Us
                </Button>
              </Grid>
              <Grid
                md={!userData?.users.roles ? 2 : 1}
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {cookies[config.cookieName]?.loginUserId ? (
                  <>
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={
                        openProfileMenu ? "account-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openProfileMenu ? "true" : undefined}
                    >
                      <Avatar
                        sx={{
                          width: 32,
                          height: 32,
                          textTransform: "uppercase",
                        }}
                      >
                        {userData?.users.name[0]}
                      </Avatar>
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={openProfileMenu}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{
                        horizontal: "right",
                        vertical: "top",
                      }}
                      anchorOrigin={{
                        horizontal: "right",
                        vertical: "bottom",
                      }}
                    >
                      {userData?.users.roles.includes("Student") ? (
                        <MenuItem onClick={handleClose}>
                          <Avatar /> Profile
                        </MenuItem>
                      ) : (
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            dashboard();
                          }}
                        >
                          <ListItemIcon>
                            <PersonAdd fontSize="small" />
                          </ListItemIcon>
                          DashBoard
                        </MenuItem>
                      )}
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          navigate("/logout");
                        }}
                      >
                        <ListItemIcon>
                          <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                      </MenuItem>
                    </Menu>
                  </>
                ) : (
                  <>
                    {!isMd ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography sx={{ textAlign: "center" }}>
                            99001 27097
                          </Typography>
                          <ButtonGroup
                            variant="outlined"
                            aria-label="outlined button group"
                            sx={{
                              flexDirection: { sm: "row", xs: "column" }, // Set to column under 600px
                            }}
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                color: "white",
                                borderColor: "#fff",
                                "&:hover": {
                                  backgroundColor: "#fff", // Change to the desired hover color
                                  color: "#000", // Change to the desired hover text color
                                },
                              }}
                              size="small"
                              onClick={handleIsLoginModal}
                            >
                              Login
                            </Button>
                            <Button
                              variant="outlined"
                              sx={{
                                color: "white",
                                borderColor: "#fff",
                                "&:hover": {
                                  backgroundColor: "#fff", // Change to the desired hover color
                                  color: "#000", // Change to the desired hover text color
                                },
                              }}
                              onClick={handleIsSignUpModal}
                              size="small"
                            >
                              SignUp
                            </Button>
                          </ButtonGroup>
                        </Box>
                      </>
                    ) : null}
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </AppBar>

      <SignUp
        open={openSignUp}
        close={handleIsSignUpModal}
        loginOpen={handleIsLoginModal}
      />
      <Login
        open={openLogin}
        close={handleIsLoginModal}
        signUpOpen={handleIsSignUpModal}
      />

      {/* below the drawer for mobile version menu related  */}

      {/* below the drawer was mobile menu drawer  */}
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <Box
          sx={{
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, rgba(52, 68, 73, 0.20) 0%, rgba(0, 0, 0, 0.00) 46.64%, rgba(14, 194, 251, 0.20) 85.38%), #162E7C",
            p: 1,
          }}
        >
          <Link to={"/"}>
            <img src="/logo-1.png" alt="logo" height="50px" />
          </Link>
        </Box>
        <Divider />
        <List sx={{ paddingTop: "0px" }}>
          {/* <MobileMenu /> */}
          <ListItemButton onClick={() => navigate("/")}>
            <ListItemIcon sx={{ color: "black" }}>
              <KeyboardArrowRightIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon sx={{ color: "black" }}>
              {/* <KeyboardArrowDownIcon /> */}
              <KeyboardArrowRightIcon />
            </ListItemIcon>
            <ListItemText
              color="inherit"
              onClick={handleAddressDrawer}
              sx={{ fontSize: "13px" }}
            >
              {(cookies[config.preferencesCookie] &&
                cookies[config.preferencesCookie].city) ||
                "Bengaluru"}
            </ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              handleCollegeSubMenuProgramDrawer();
              setProgramValue("college");
            }}
          >
            <ListItemIcon sx={{ color: "black" }}>
              {/* <KeyboardArrowDownIcon /> */}
              <KeyboardArrowRightIcon />
            </ListItemIcon>
            <ListItemText primary="Colleges" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              handleSchoolProgramDrawer();
              // setProgramFetching(true);
              // setProgramValue("school");
            }}
          >
            <ListItemIcon sx={{ color: "black" }}>
              {/* <KeyboardArrowDownIcon /> */}
              <KeyboardArrowRightIcon />
            </ListItemIcon>
            <ListItemText primary="Schools" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              handleTrainingProgramDrawer();
              setProgramFetching(true);
              setProgramValue("training institute");
            }}
          >
            <ListItemIcon sx={{ color: "black" }}>
              {/* <KeyboardArrowDownIcon /> */}
              <KeyboardArrowRightIcon />
            </ListItemIcon>
            <ListItemText primary="Training Institutes" />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/about-us")}>
            <ListItemIcon sx={{ color: "black" }}>
              <KeyboardArrowRightIcon />
            </ListItemIcon>
            <ListItemText primary="About Us" />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/contact-us")}>
            <ListItemIcon sx={{ color: "black" }}>
              <KeyboardArrowRightIcon />
            </ListItemIcon>
            <ListItemText primary="Contact Us" />
          </ListItemButton>
        </List>
        {!cookies[config.cookieName]?.loginUserId ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#000",
                  "&:hover": {
                    backgroundColor: "#fff", // Change to the desired hover color
                    color: "#000", // Change to the desired hover text color
                  },
                }}
                onClick={handleIsLoginModal}
              >
                Login
              </Button>
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#000",
                  "&:hover": {
                    backgroundColor: "#0000", // Change to the desired hover color
                    color: "#000", // Change to the desired hover text color
                  },
                }}
                onClick={handleIsSignUpModal}
              >
                SingUp
              </Button>
            </ButtonGroup>
          </Box>
        ) : null}
      </Drawer>

      {/* Address Drawer */}
      <Drawer
        open={addressDrawer}
        onClose={handleAddressDrawer}
        anchor="right"
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <IconButton onClick={handleAddressDrawer}>
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Divider />
        <List>
          <ListItem>
            <Input
              placeholder="Search"
              value={searchText}
              onChange={handleSearchChange}
              fullWidth
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </ListItem>
          <ListItem sx={{ cursor: "default", fontWeight: "bold" }}>
            <ListItemText
              primary="Top Cities"
              sx={{
                fontSize: "13px",
                textDecoration: "underline",
              }}
            />
          </ListItem>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              px: "15px",
              columnGap: "10px",
            }}
          >
            {filteredTopCities?.map((cities) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid gray",
                  borderRadius: "5px",
                  my: "10px",
                  p: "8px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCookie(
                    config.preferencesCookie,
                    JSON.stringify({
                      ...cookies[config.preferencesCookie],
                      city: cities.name,
                    }),
                    {
                      path: "/",
                      maxAge: 3000000,
                      sameSite: "strict",
                    }
                  );
                  handleAddressDrawer();
                }}
                key={cities.name}
              >
                <img src={cities.svg} height="30px" alt="cities" />
                <Typography
                  variant="caption"
                  sx={{ "&:hover": { color: "blue" } }}
                >
                  {cities.name}
                </Typography>
              </Box>
            ))}
          </Box>
          <ListItem sx={{ cursor: "default", fontWeight: "bold" }}>
            <ListItemText
              primary="Other Cities"
              sx={{
                fontSize: "13px",
                textDecoration: "underline",
              }}
            />
          </ListItem>
          {filteredOtherCities?.map((subitem, subIndex) => (
            <ListItem
              key={subIndex}
              button
              onClick={() => {
                setCookie(
                  config.preferencesCookie,
                  JSON.stringify({
                    ...cookies[config.preferencesCookie],
                    city: subitem,
                  }),
                  {
                    path: "/",
                    maxAge: 3000000,
                    sameSite: "strict",
                  }
                );
                handleAddressDrawer();
              }}
            >
              <ListItemText primary={subitem} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* mobile menu college program submenu drawer  */}
      <Drawer
        open={collegeSubMenuProgramDrawer}
        onClose={handleCollegeSubMenuProgramDrawer}
        anchor="right"
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <IconButton
            onClick={() => {
              handleCollegeSubMenuProgramDrawer();
              setOpenDrawer(true);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Divider />
        <List>
          {programList?.map((item, index) => (
            <ListItem
              key={index}
              button
              onClick={() => {
                setActiveProgram(item.id);
                handleCollegeSubMenuCourseDrawer();
              }}
            >
              <ListItemText primary={item.programName} />
              <ListItemIcon>
                <ArrowForwardIosIcon />{" "}
                {/* Replace YourEndIcon with your actual end icon component */}
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* mobile menu college course submenu drawer  */}
      <Drawer
        open={collegeSubMenuCourseDrawer}
        onClose={handleCollegeSubMenuCourseDrawer}
        anchor="right"
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <IconButton
            onClick={() => {
              handleCollegeSubMenuCourseDrawer();
              handleCollegeSubMenuProgramDrawer();
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Divider />
        <List>
          {courseList?.slice(0, 10).map((item, index) => (
            <ListItem key={index}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color:
                    cookies[config.filterCookie]?.course === item.id
                      ? "#007bff"
                      : "#7f8c8d",
                  cursor: "pointer",
                  justifyContent: "space-between",
                  width: "100%",
                }}
                onClick={() => {
                  handleCollegeSubMenuSpecializationDrawer();
                  handleCollegeSubMenuCourseDrawer();
                }}
              >
                {item.courseName}
                <ArrowForwardIcon />
              </Typography>
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* mobile menu college specialization submenu drawer  */}
      <Drawer
        open={collegeSubMenuSpecializationDrawer}
        onClose={handleCollegeSubMenuSpecializationDrawer}
        anchor="right"
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <IconButton
            onClick={() => {
              handleCollegeSubMenuSpecializationDrawer();
              handleCollegeSubMenuCourseDrawer();
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Divider />
        <List>
          {specializationList?.length > 0 && (
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                Colleges By Specialization
              </Typography>
              <List sx={{ fontSize: "14px" }}>
                {specializationList.map((item, index) => (
                  <ListItem key={index}>
                    <Link
                      to="/colleges/college"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                        color: "#007bff", // Blue color for list items
                      }}
                    >
                      {item.specialization}
                    </Link>
                  </ListItem>
                ))}
                <ListItem>
                  <Link
                    to="/colleges/college"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      color: "#95a5a6",
                    }}
                  >
                    <ListItemText primary="View All" />
                    <ListItemIcon>
                      <ChevronRightIcon />
                    </ListItemIcon>
                  </Link>
                </ListItem>
              </List>
            </Box>
          )}
        </List>
      </Drawer>

      {/* mobile menu school submenu drawer  */}
      <Drawer
        open={schoolProgramDrawer}
        onClose={handleSchoolProgramDrawer}
        anchor="right"
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <IconButton
            onClick={() => {
              handleSchoolProgramDrawer();
              setOpenDrawer(true);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Divider />
        <List>
          <ListItem button onClick={() => navigate("/schools/cbse")}>
            <ListItemText primary={"CBSE"} />
            <ListItemIcon>
              <ArrowForwardIosIcon />
            </ListItemIcon>
          </ListItem>
          <ListItem button onClick={() => navigate("/schools/igcse")}>
            <ListItemText primary={"IGCSE"} />
            <ListItemIcon>
              <ArrowForwardIosIcon />
            </ListItemIcon>
          </ListItem>
          <ListItem button onClick={() => navigate("/schools/state-board")}>
            <ListItemText primary={"State Board"} />
            <ListItemIcon>
              <ArrowForwardIosIcon />
            </ListItemIcon>
          </ListItem>
          <ListItem button onClick={() => navigate("/schools/ib")}>
            <ListItemText primary={"IB"} />
            <ListItemIcon>
              <ArrowForwardIosIcon />
            </ListItemIcon>
          </ListItem>
          <ListItem button onClick={() => navigate("/schools/nios")}>
            <ListItemText primary={"NIOS"} />
            <ListItemIcon>
              <ArrowForwardIosIcon />
            </ListItemIcon>
          </ListItem>
          <ListItem button onClick={() => navigate("/schools/aissce")}>
            <ListItemText primary={"AISSCE"} />
            <ListItemIcon>
              <ArrowForwardIosIcon />
            </ListItemIcon>
          </ListItem>
        </List>
      </Drawer>

      {/* mobile menu training submenu drawer  */}
      <Drawer
        open={trainingProgramDrawer}
        onClose={handleTrainingProgramDrawer}
        anchor="right"
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <IconButton
            onClick={() => {
              handleTrainingProgramDrawer();
              setOpenDrawer(true);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Divider />
        <List>
          {trainingInstituteProgramList?.map((item, index) => (
            <ListItem
              key={index}
              button
              onClick={() =>
                navigate(
                  `/training-institutes?program=${item.trainingInstituteProgram}`
                )
              }
            >
              <ListItemText primary={item.trainingInstituteProgram} />
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default Header;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
