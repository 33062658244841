import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  TextField,
  Box,
  Autocomplete,
  Button,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputAdornment,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ClearIcon from "@mui/icons-material/Clear";
import {
  invokeApi,
  apiList,
  invokeFormDataApi,
} from "../../../services/apiServices";
import { useCookies } from "react-cookie";
import { config } from "../../../config/config";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import TextEditor from "./TextEditor";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Autocomplete as MapAutoComplete,
} from "@react-google-maps/api";
import { useRef } from "react";
import { toast } from "react-toastify";
import CollegeCourse from "./CollegeCourse";
import CollegeTabs from "./CollegeTabs";

const libraries = ["places"];

const College = () => {
  // cookies for fetching
  const [cookies] = useCookies();
  const [metadataFetching, setMetadataFetching] = useState(true);
  // navigate to another page
  const navigate = useNavigate();
  // google map ref
  const mapRef = useRef(null);

  const [saveLoading, setSaveLoading] = useState(false);

  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  // all required data list
  const [universityList, setUniversityList] = useState([]);
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [scholorshipList, setScholorshipList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [placementCompaniesList, setPlacementCompaniesList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [instituteBrandList, setInstituteBrandList] = useState([]);
  const [collegeTypeList, setCollegeTypeList] = useState([]);
  // const [instituteTypeList, setInstituteTypeList] = useState([]);

  // college name
  const [collegeName, setCollegeName] = useState({
    name: "",
    error: false,
    helperText: "",
  });

  // og url
  const [ogUrl, setOgUrl] = useState({
    url: "",
    error: false,
    helperText: "",
  });

  // university
  const [university, setUniversity] = useState({
    id: "",
    error: false,
    helperText: "",
  });

  const [summary, setSummary] = useState({
    admission: "",
    placementCompany: "",
    cutOff: "",
    scholarship: "",
    courseFee: "",
    result: "",
    qna: "",
    description: "",
    metaDescription: {
      content: "",
      error: false,
      helperText: "",
    },
    schemaScript: "",
    keywords: "",
  });
  const handleTextChange = (key, value) => {
    setSummary((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const [youtubeUrl, setYoutubeUrl] = useState({
    urls: "",
    error: false,
    helperText: "",
  });

  const [emails, setEmails] = useState("");
  const [trustName, setTrustName] = useState("");
  const [collegeCategory, setCollegeCategory] = useState("co-ed");
  const [contactNumbers, setContactNumbers] = useState("");
  const [website, setWebsite] = useState("");

  const [autocomplete, setAutocomplete] = useState("");

  const [videoLinks, setVideoLinks] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(null);

  const handleAddVideo = () => {
    const youtubeUrlRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/(.*\/)?|youtu\.be\/)([^\?&"'>]+)/;

    if (!youtubeUrl.urls) {
      setYoutubeUrl({
        urls: youtubeUrl.urls,
        error: true,
        helperText: "Please Paste Youtube Url",
      });
    } else if (!youtubeUrlRegex.test(youtubeUrl.urls)) {
      setYoutubeUrl({
        urls: youtubeUrl.urls,
        error: true,
        helperText: "Please enter a valid YouTube URL",
      });
    } else {
      const newVideoObject = {
        mediaType: "youtube",
        mediaFilePath: youtubeUrl.urls,
      };

      setVideoLinks((prevLinks) => [...prevLinks, newVideoObject]);
      setYoutubeUrl("");
    }
  };

  const handleRemoveVideo = (index) => {
    setSelectedVideoIndex(index);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    if (selectedVideoIndex !== null) {
      setVideoLinks((prevLinks) =>
        prevLinks.filter((_, index) => index !== selectedVideoIndex)
      );
      setSelectedVideoIndex(null);
    }
  };

  const [amenities, setAmenities] = useState([
    { amenityId: "", amenityDescription: "" },
  ]);

  const [amenityIdError, setAmenityIdError] = useState(false);
  const [amenityIdHelperText, setAmenityIdHelperText] = useState("");

  const handleAddRow = () => {
    setAmenities([...amenities, { amenityId: "", amenityDescription: "" }]);
  };

  const handleRemoveRow = (index) => {
    const updatedData = [...amenities];
    updatedData.splice(index, 1);
    setAmenities(updatedData);
  };

  const handleChange = (index, field, value) => {
    const updatedData = [...amenities];
    updatedData[index][field] = value;
    setAmenities(updatedData);
  };

  // established year and validate
  const [establishedYear, setEstablishedYear] = useState({
    year: "",
    error: false,
    helperText: "",
  });

  // scholorship id and validation
  const [scholarship, setScholarship] = useState({
    ids: [],
    error: false,
    helperText: "",
  });

  // program id and validation
  const [program, setProgram] = useState({
    id: "",
    error: false,
    helperText: "",
  });

  // scholorship cutoff and validation
  const [scholorShipCutOff, setScholorShipCutOff] = useState({
    cutOff: "",
    error: false,
    helperText: "",
  });

  const [placementCompany, setPlacementCompany] = useState({
    ids: [],
    error: false,
    helperText: "",
  });

  // college Type list and college type selected and validation

  const [collegeType, setCollegeType] = useState({
    id: "",
    error: false,
    helperText: "",
  });

  const [instituteBrand, setInstituteBrand] = useState({
    id: "",
    error: false,
    helperText: "",
  });

  // course list fetch

  // country selected and validation
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [countryHelperText, setCountryHelperText] = useState("");

  // state selected and validation
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState(false);
  const [stateHelperText, setStateHelperText] = useState("");

  // district selected and validation
  const [district, setDistrict] = useState("");
  const [districtError, setDistrictError] = useState(false);
  const [districtHelperText, setDistrictHelperText] = useState("");

  // area and validation
  const [area, setArea] = useState("");
  const [areaError, setAreaError] = useState(false);
  const [areaHelperText, setAreaHelperText] = useState("");

  // pincode selected and validation
  const [pincode, setPincode] = useState("");
  const [pincodeError, setPincodeError] = useState(false);
  const [pincodeHelperText, setPincodeHelperText] = useState("");

  // add course multiple row and validation

  // const collegeTypeList = ["Government", "Government Aided", "Private"];

  // below the code is add rows in course add
  const courseTemplate = {
    courseId: "",
    courseFee: "",
    batchCapacity: "",
    years: "",
    months: "0",
    courseSpecializationId: "",
    collegeCourseEntryCutoffList: [
      {
        examId: "",
        cutoff: "",
        cutoffType: "",
      },
    ],
    collegeCourseScholarshipCutoffList: [
      {
        examId: "",
        cutoff: "",
        cutoffType: "",
        scholarship: "",
        scholarshipId: "",
        scholarshipType: "",
      },
    ],
  };
  const [course, setCourse] = useState([courseTemplate]);
  const [courseNameError, setCourseNameError] = useState(false);
  const [courseFeeError, setCourseFeeError] = useState(false);

  const [courseNameHelperText, setCourseNameHelperText] = useState("");
  const [courseFreeHelperText, setCourseFreeHelperText] = useState("");

  const [courseBatchCapacityError, setCourseBatchCapacityError] =
    useState(false);
  const [courseBatchCapacityHelperText, setCourseBatchCapacityHelperText] =
    useState("");

  const [specializationIdError, setSpecializationIdError] = useState(false);
  const [specializationIdHelperText, setSpecializationIdHelperText] =
    useState("");

  const [yearError, setYearError] = useState(false);
  const [yearHelperText, setYearHelperText] = useState("");

  const [monthError, setMonthError] = useState(false);
  const [monthHelperText, setMonthHelperText] = useState("");

  // google map
  // search address and auto fill location
  const [markerPosition, setMarkerPosition] = useState({
    lat: config.defaultLocation.lat,
    lng: config.defaultLocation.lng,
  });

  // college logo, preview, college logo path and validation
  const [collegeLogo, setCollegeLogo] = useState(null);
  const [collegeLogoError, setCollegeLogoError] = useState(false);
  const [collegeLogoHelperText, setCollegeLogoHelperText] = useState("");
  const [previewCollegeLogo, setPreviewCollegeLogo] = useState(null);
  const [collegeLogomaxFileSizeErr, setCollegeLogoMaxFileSizeErr] =
    useState("");
  const [collegeLogoPath, setCollegeLogoPath] = useState(null);

  // Banner logo, preview, Banner logo path and validation
  const [bannerLogo, setBannerLogo] = useState(null);
  const [bannerLogoHelperText, setBannerLogoHelperText] = useState("");
  const [bannerLogoError, setBannerLogoError] = useState(false);
  const [previewBannerLogo, setPreviewBannerLogo] = useState(null);
  const [bannerPath, setBannerPath] = useState(null);

  // Broucher logo, preview, Broucher logo path and validation
  const [broucherLogo, setBroucherLogo] = useState(null);
  const [broucherError, setBroucherError] = useState(false);
  const [broucherHelperText, setBroucherHelperText] = useState("");
  const [previewBroucherLogo, setPreviewBroucherLogo] = useState(null);
  const [broucherPath, setBroucherPath] = useState(null);

  // gallery image, preview, gallery images path and validation
  const [previewImages, setPreviewImages] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryImageURLs, setGalleryImageURLs] = useState([]);
  const [galleryImageError, setGalleryImageError] = useState(false);
  const [galleryImageHelperText, setGalleryImageHelperText] = useState("");

  // below the line useState is for save college when it is true save function will call
  const [galleryImageUploadStatus, setGalleryImageUploadStatus] =
    useState(false);

  const ClearTextCache = () => {
    setSaveLoading(false);

    setCollegeName({ name: "", error: false, helperText: "" });

    setAmenities([{ amenityId: "", amenityDescription: "" }]);

    setUniversity({
      id: null,
      error: false,
      helperText: "",
    });

    setOgUrl({ url: "", error: false, helperText: "" });

    setProgram({ id: "", error: false, helperText: "" });

    setSpecializationIdError(false);
    setSpecializationIdHelperText("");

    setCollegeLogoPath("");
    setPreviewCollegeLogo(null);
    setCollegeLogoHelperText("");
    setCollegeLogo(null);
    setCollegeLogoError(false);
    setCollegeLogoMaxFileSizeErr("");

    setBannerLogo(null);
    setBannerLogoHelperText("");
    setPreviewBannerLogo(null);
    setBannerPath("");
    setBannerLogoError(false);

    setBroucherLogo(null);
    setPreviewBroucherLogo(null);

    setPreviewImages([]);
    setGalleryImages([]);
    setGalleryImageURLs([]);
    setGalleryImageError(false);
    setGalleryImageHelperText("");

    setEstablishedYear({
      year: establishedYear.year,
      error: false,
      helperText: "",
    });

    setDistrict("");
    setDistrictError(false);
    setDistrictHelperText("");

    setState("");
    setStateError(false);
    setStateHelperText("");

    setPincode("");
    setPincodeError(false);
    setPincodeHelperText("");

    setArea("");
    setAreaError(false);
    setAreaHelperText("");

    setInstituteBrand({ id: "", error: false, helperText: "" });

    setCollegeType({ id: "", error: false, helperText: "" });

    setPlacementCompany({ ids: [], error: false, helperText: "" });

    setScholorShipCutOff({ cutOff: "", error: false, helperText: "" });

    setScholarship({ ids: [], error: false, helperText: "" });

    setCourse([courseTemplate]);
    setCourseNameError(false);
    setCourseFeeError(false);
    setCourseBatchCapacityError(false);
    setCourseNameHelperText("");
    setCourseFreeHelperText("");
    setCourseBatchCapacityHelperText("");
    setSpecializationIdHelperText("");
  };

  const [summaryImage, setSummaryImage] = useState(null);
  const [summaryImageUrl, setSummaryImageUrl] = useState(null);

  const [videoFiles, setVideoFiles] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [videoUrl, setVideoUrl] = useState([]);
  const mergedMediaFiles = [...galleryImageURLs, ...videoLinks, ...videoUrl];

  const handleRemoveFile = (index) => {
    const updatedFiles = [...videoFiles];
    const removedFile = updatedFiles.splice(index, 1)[0];
    setTotalSize(totalSize - removedFile.size);
    setVideoFiles(updatedFiles);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    // Calculate total size of selected files
    const size = files.reduce((acc, file) => acc + file.size, 0);

    // Check if total size exceeds 100MB
    if (size > 100 * 1024 * 1024) {
      alert("Total size exceeds 100MB. Please select smaller files.");
      e.target.value = null; // Clear selected files
      return;
    }

    setVideoFiles([...videoFiles, ...files]);
    setTotalSize(totalSize + size);
  };

  const fileValidation = async (e, fileType) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded) {
      // Check if the file format is supported
      const supportedFormats = ["image/jpeg", "image/jpg", "image/png"];

      let validate =
        fileType === "brochure image" ? ["application/pdf"] : supportedFormats;

      let maxSize =
        fileType === "brochure image" ? 25 * 1024 * 1024 : 512 * 512;

      if (validate.includes(fileUploaded.type)) {
        if (fileUploaded.size > maxSize) {
          try {
            const compressedFile = await fileCompress(fileUploaded);
            switch (fileType) {
              case "summary":
                setSummaryImage(compressedFile);
                break;
              case "college logo":
                setCollegeLogo(compressedFile);
                setPreviewCollegeLogo(URL.createObjectURL(fileUploaded));
                break;
              case "banner image":
                setBannerLogo(compressedFile);
                setPreviewBannerLogo(URL.createObjectURL(fileUploaded));
                break;
              case "brochure image":
                setBroucherLogo(compressedFile);
                setPreviewBroucherLogo("./media/png/pdf-icon.png");
                break;
              default:
                alert("Please Upload Valide File");
                break;
            }

            // setPreviewCollegeLogo(URL.createObjectURL(compressedFile));
            const uploadResult = await fileUpload(compressedFile, fileType);
            if (uploadResult.success) {
              switch (fileType) {
                case "summary":
                  setSummaryImageUrl(uploadResult.url);
                  break;
                case "college logo":
                  setCollegeLogoPath(uploadResult.url);
                  break;
                case "banner image":
                  setBannerPath(uploadResult.url);
                  break;
                case "brochure image":
                  setBroucherPath(uploadResult.url);
                  setPreviewBroucherLogo("./media/png/pdf-icon.png");
                  break;
                default:
                  alert("Please Upload Valide File");
                  break;
              }
            } else {
              alert(
                `Something went wrong while uploading ${fileType}. Please try again later!`
              );
            }
          } catch (error) {
            console.error(
              "Error while processing and uploading the image:",
              error
            );
            alert(
              "Something went wrong while processing the image. Please try again later!"
            );
          }
        } else {
          // setPreviewCollegeLogo(URL.createObjectURL(fileUploaded));
          switch (fileType) {
            case "summary":
              setSummaryImage(fileUploaded);
              break;
            case "college logo":
              setCollegeLogo(fileUploaded);
              setPreviewCollegeLogo(URL.createObjectURL(fileUploaded));
              break;
            case "banner image":
              setBannerLogo(fileUploaded);
              setPreviewBannerLogo(URL.createObjectURL(fileUploaded));
              break;
            case "brochure image":
              setBroucherLogo(fileUploaded);
              setPreviewBroucherLogo("./media/png/pdf-icon.png");
              break;
            default:
              alert("Please Upload Valide File");
              break;
          }
          const uploadResult = await fileUpload(fileUploaded, fileType);
          if (uploadResult.success) {
            switch (fileType) {
              case "summary":
                setSummaryImageUrl(uploadResult.url);
                break;
              case "college logo":
                setCollegeLogoPath(uploadResult.url);
                break;
              case "banner image":
                setBannerPath(uploadResult.url);
                break;
              case "brochure image":
                setBroucherPath(uploadResult.url);
                setPreviewBroucherLogo("./media/png/pdf-icon.png");
                break;
              default:
                alert("Please Upload Valide File");
                break;
            }
          } else {
            alert(
              "Something went wrong while uploading Summary Image. Please try again later!"
            );
          }
        }
      } else {
        alert("Please upload a valid image in jpeg/jpg/png/gif format.");
      }
    }
  };

  const fileUpload = async (file, fileType) => {
    let path =
      (fileType === "summary" && "Summary_Images") ||
      (fileType === "college logo" && "College_Logo") ||
      (fileType === "brochure image" && "Broucher_Image") ||
      (fileType === "banner image" && "Banner_Image");
    try {
      let formDataCollegeLogo = new FormData();
      formDataCollegeLogo.append("file", file);
      formDataCollegeLogo.append("path", path);

      let response = await invokeFormDataApi(
        config.getMyCollege + apiList.uploadFile,
        formDataCollegeLogo,
        cookies
      );

      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          return { success: true, url: response.data.url };
        } else {
          return { success: false };
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        return { success: false };
      }
    } catch (error) {
      console.error("Error while uploading college logo:", error);
      return { success: false };
    }
  };

  const fileCompress = async (file) => {
    return new Promise((resolve, reject) => {
      if (file.type.includes("image")) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const image = new Image();
          image.src = event.target.result;
          image.onload = () => {
            const canvas = document.createElement("canvas");
            const MAX_SIZE = 512 * 512;
            let width = image.width;
            let height = image.height;

            while (width * height > MAX_SIZE) {
              width *= 0.9;
              height *= 0.9;
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, width, height);

            canvas.toBlob(
              (blob) => {
                const compressedFile = new File([blob], file.name, {
                  type: "image/jpeg",
                  lastModified: Date.now(),
                });
                resolve(compressedFile);
              },
              "image/jpeg",
              0.8
            );
          };
        };
        reader.readAsDataURL(file);
      } else if (file.type === "application/pdf") {
        resolve(file);
      } else {
        resolve(file);
      }
    });
  };

  // gallery image validation
  const handleImageGalleryUpload = async (ev) => {
    setGalleryImageError(false);
    setGalleryImageHelperText("");
    if (ev.target.files) {
      const images = ev.target.files;
      const compressedImages = [];

      for (let i = 0; i < images.length; i++) {
        const img = images[i];
        if (!img.type.match(/^image\/(jpe?g|png|gif)/)) {
          alert("Please upload a valid image in jpeg/jpg/png/gif format");
        } else {
          const compressedImg =
            img.size > 512 * 512 ? await compressImage(img) : img;
          compressedImages.push(compressedImg);
        }
      }

      setPreviewImages((prevImages) =>
        prevImages
          .slice(0, 14)
          .concat(compressedImages.map((img) => URL.createObjectURL(img)))
      );
      setGalleryImages((prevImages) =>
        prevImages.slice(0, 14).concat(compressedImages)
      );
    }
  };

  // gallery image comperss each
  const compressImage = (image) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const MAX_SIZE = 1024 * 1024;
          let width = img.width;
          let height = img.height;

          while (width * height > MAX_SIZE) {
            width *= 0.9;
            height *= 0.9;
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], image.name, {
                type: image.type,
              });
              resolve(compressedFile);
            },
            image.type,
            0.8
          );
        };

        img.src = event.target.result;
      };

      reader.readAsDataURL(image);
    });
  };

  // Remove gallery image
  const deleteImageGallery = (idx) => {
    let gllryImages = galleryImages;
    gllryImages.splice(idx, 1);
    setGalleryImages([...gllryImages]);

    let prvwImages = previewImages;
    prvwImages.splice(idx, 1);
    setPreviewImages([...prvwImages]);
  };

  // onsubmit active this function check the required fields and upload the gallery image and callback college save function
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaveLoading(true);
    let validationError = false;
    if (!collegeName.name) {
      setCollegeName({
        name: collegeName.name,
        error: true,
        helperText: "Please Enter College Name",
      });
      setSaveLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }
    if (!university.id) {
      setUniversity({
        id: university.id,
        error: true,
        helperText: "Please Select University",
      });
      validationError = true;
      setSaveLoading(false);
      setGalleryImageUploadStatus(false);
    }

    if (!amenities[0].amenityId) {
      setAmenityIdHelperText("Please Select amenity");
      setAmenityIdError(true);
      validationError = true;
      setSaveLoading(false);
      setGalleryImageUploadStatus(false);
    }

    if (!ogUrl.url) {
      setOgUrl({
        url: ogUrl.url,
        error: true,
        helperText: "Please select Og Url",
      });
      validationError = true;
      setGalleryImageUploadStatus(false);
    }

    if (!program.id) {
      setProgram({
        id: program.id,
        error: true,
        helperText: "Please Select Program",
      });
      setSaveLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }
    if (!course[0].courseId) {
      setCourseNameError(true);
      setCourseNameHelperText("Please Select Atleast One course");
      setSaveLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }
    if (!course[0].courseFee) {
      setCourseFeeError(true);
      setCourseFreeHelperText("Please Enter Select Course Fee");
      setSaveLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }
    if (!course[0].batchCapacity) {
      setCourseBatchCapacityError(true);
      setCourseBatchCapacityHelperText(
        "Please Enter Select Course Bactch Capacity"
      );
      setSaveLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }

    if (!course[0].courseSpecializationId) {
      setSpecializationIdError(true);
      setSpecializationIdHelperText(
        "Please Enter Specialization for Select Course"
      );
      setSaveLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }
    if (!course[0].years) {
      setYearError(true);
      setYearHelperText("Please Enter year for Select Course");
      setSaveLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }

    if (!course[0].months) {
      setMonthError(true);
      setMonthHelperText("Please Enter year for Select Course");
      setSaveLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }

    if (!collegeLogoPath) {
      setCollegeLogoError(true);
      setCollegeLogoHelperText("Please Upload College Logo");
      setSaveLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }
    if (!broucherPath) {
      setBroucherError(true);
      setBroucherHelperText("Please Upload Broucher");
      setSaveLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }

    if (galleryImages.length <= 0) {
      setGalleryImageError(true);
      setGalleryImageHelperText("Please Upload Atleast One Image");
      setSaveLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }
    if (!establishedYear.year) {
      setEstablishedYear({
        year: establishedYear.year,
        error: true,
        helperText: "Please Enter College Established Year",
      });
      setSaveLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }
    if (!country) {
      setCountryError(true);
      setCountryHelperText("Please Select Country");
      setSaveLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }
    if (!state) {
      setStateError(true);
      setStateHelperText("Please Select State");
      validationError = true;
      setSaveLoading(false);
      setGalleryImageUploadStatus(false);
    }
    if (!district) {
      setDistrictError(true);
      setDistrictHelperText("Please Enter District");
      validationError = true;
      setGalleryImageUploadStatus(false);
      setSaveLoading(false);
    }
    if (!pincode) {
      setPincodeError(true);
      setPincodeHelperText("Please Enter Postal Code");
      validationError = true;
      setGalleryImageUploadStatus(false);
      setSaveLoading(false);
    }
    if (!area) {
      setAreaError(true);
      setAreaHelperText("Please Enter Area and Road");
      setSaveLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }

    if (!instituteBrand.id) {
      setInstituteBrand({
        id: instituteBrand.id,
        error: true,
        helperText: "Please Select Insitute Type",
      });
      setSaveLoading(false);
      validationError = true;
      setGalleryImageUploadStatus(false);
    }

    if (placementCompany.ids.length <= 0) {
      setPlacementCompany({
        ids: placementCompany.ids,
        error: true,
        helperText: "Please Select Atleast One Placement Company",
      });
      validationError = true;
      setGalleryImageUploadStatus(false);
      setSaveLoading(false);
    }
    if (!validationError) {
      for (let i = 0; i < galleryImages.length; i++) {
        let formDataGallery = new FormData();
        formDataGallery.append("file", galleryImages[i]);
        formDataGallery.append("path", "College_Gallery_Image");

        let responseGall = await invokeFormDataApi(
          config.getMyCollege + apiList.uploadFile,
          formDataGallery,
          cookies
        );

        if (responseGall?.status >= 200 && responseGall?.status < 300) {
          if (responseGall.data.responseCode === "200") {
            setGalleryImageURLs((prevState) => [
              ...prevState,
              {
                mediaType: "Image",
                mediaFilePath: responseGall.data.url,
              },
            ]);
          } else {
            alert(
              "Something went wrong while uploading Gallery images. Please try again later!"
            );
          }
        } else if (responseGall?.status === 401) {
          navigate("/logout");
        } else {
          alert(
            "Something went wrong while uploading Gallery images. Please try again later!!"
          );
          setGalleryImageUploadStatus(false);
        }
      }
      for (let i = 0; i < videoFiles.length; i++) {
        let formDataVideos = new FormData();
        formDataVideos.append("file", videoFiles[i]);
        formDataVideos.append("path", "college_videos");

        let response = await invokeFormDataApi(
          config.getMyCollege + apiList.uploadVideo,
          formDataVideos,
          cookies
        );

        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.responseCode === "200") {
            setVideoUrl((prevState) => [
              ...prevState,
              {
                mediaType: "video",
                mediaFilePath: response.data.url,
              },
            ]);
          } else {
            alert(
              "Something went wrong while uploading Video. Please try again later!"
            );
          }
        } else if (response?.status === 401) {
          navigate("/logout");
        } else {
          alert(
            "Something went wrong while uploading Gallery images. Please try again later!!"
          );
          setGalleryImageUploadStatus(false);
        }
      }
      setGalleryImageUploadStatus(true);
    }
  };

  // below the code is for slug url based on college name
  const handleCollegeNameChange = (e) => {
    const newValue = e.target.value;
    setCollegeName({ name: newValue, error: false, helperText: "" });
    const formattedValue = newValue.replace(/ /g, "-");
    setOgUrl({
      url: formattedValue,
      error: false,
      helperText: "",
    });
  };

  // pincode dropdown options function
  const pincodeList = (
    addressList
      .find((item) => item.countryName === country)
      ?.states.find((item) => item.stateName === state)
      ?.districts.find((item) => item.districtName === district)?.pincodes || []
  ).map((pincode) => pincode.pincode);

  // district options function
  const districtList = (
    addressList
      .find((item) => item.countryName === country)
      ?.states.find((item) => item.stateName === state)?.districts || []
  ).map((district) => district.districtName);

  // meta description character validation
  const maxCharacters = 155;
  const handleMetaDescriptionChange = (e) => {
    const newValue = e.target.value;
    const characterCount = newValue.length;

    if (characterCount >= 70 && characterCount <= maxCharacters) {
      setSummary((prevState) => ({
        ...prevState,
        metaDescription: {
          content: newValue,
          error: false,
          helperText: "",
        },
      }));
    } else {
      setSummary((prevState) => ({
        ...prevState,
        metaDescription: {
          content: newValue,
          error: true,
          helperText: "Meta description must be between 70 and 155 characters.",
        },
      }));
    }
  };

  // below the code all is related to google map

  const onMarkerDragEnd = (coords) => {
    setMarkerPosition({
      lat: coords.latLng.lat(),
      lng: coords.latLng.lng(),
    });
  };

  const showPosition = (position) => {
    if (position.coords.latitude && position.coords.longitude) {
      setMarkerPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    }
  };

  const mapCenterMoved = () => {
    setMarkerPosition({
      lat: mapRef.current.getCenter().lat(),
      lng: mapRef.current.getCenter().lng(),
    });
  };

  const onAutoCompleteLoad = (ac) => {
    setAutocomplete(ac);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null && !!autocomplete.getPlace().geometry?.location) {
      setMarkerPosition({
        lat: autocomplete.getPlace().geometry.location.lat(),
        lng: autocomplete.getPlace().geometry.location.lng(),
      });
    }
  };

  // onchange the placement Company
  const handlePlacementChange = (event, newValue) => {
    const stringIds = newValue.map((company) => company.id.toString());

    setPlacementCompany({ ids: stringIds, error: false, helperText: "" });
  };

  const handleScholarshipChange = (event, newValue) => {
    const stringIds = newValue.map((company) => company.id.toString());

    setScholarship({
      ids: stringIds,
      error: false,
      helperText: "",
    });
  };

  const handleRemovepc = (prev) => {
    setPlacementCompany({
      ids: placementCompany.ids.filter((r) => r !== prev.id),
      error: false,
      helperText: "",
    });
  };

  const handleRemoveScholarship = (prev) => {
    setScholarship({
      ids: scholarship.ids.filter((r) => r !== prev.id),
      error: false,
      helperText: "",
    });
  };

  // use effect start from here
  useEffect(() => {
    const saveCollege = async () => {
      let params = {
        collegeName: collegeName.name,

        universityId: university.id,

        collegeLogoPath: collegeLogoPath,

        bannerImagePath: bannerPath,

        brochurePath: broucherPath,

        scholarshipList: scholarship.ids,

        scholarshipCutOff: scholorShipCutOff.cutOff,

        collegeTypeId: collegeType.id,

        instituteTypeId: "4",

        placementCompanyIdsList: placementCompany.ids,

        establishedYear: establishedYear.year,

        country: country,

        state: state,

        district: district,

        pincode: pincode,

        area: area,

        seoOgUrl: ogUrl.url,

        programId: program.id,

        instituteBrandId: instituteBrand.id,

        collegeCourses: course,

        collegeMediaFiles: mergedMediaFiles,

        collegeAmenities: amenities,

        collegeMediaFile: videoUrl,

        collegeFullDescription: summary.description,
        metaKeywords: summary.keywords,
        metaDescription: summary.metaDescription.content,
        schemaScript: summary.schemaScript,
        latitude: markerPosition.lat,
        longitude: markerPosition.lng,

        collegeCategory: collegeCategory,
        trustName: trustName,
        emails: emails,
        contactNos: contactNumbers,
        websiteUrl: website,
        admissionSummary: summary.admission,
        placementCompanySummary: summary.placementCompany,
        cutoffSummary: summary.cutOff,
        scholarshipSummary: summary.scholarship,
        courseFeeSummary: summary.courseFee,
        resultsSummary: summary.result,
        qnaSummary: summary.qna,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.saveCollege,
        params,
        cookies
      );

      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          ClearTextCache();
          toast.success("College Saved SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setGalleryImageUploadStatus(false);
          navigate("/college-list");
          setSaveLoading(false);
        } else {
          alert(
            "Something went wrong while Adding college. Please try again later!"
          );
          setGalleryImages([]);
          setGalleryImageUploadStatus(false);
          setSaveLoading(false);
        }
      } else if (response?.status === 401) {
        navigate("/logout");
        setGalleryImages([]);
      } else {
        alert(
          "Something went wrong while Adding college. Please try again later!!"
        );
        setGalleryImageUploadStatus(false);
        setSaveLoading(false);
        setGalleryImages([]);
      }
    };
    if (galleryImageUploadStatus) {
      saveCollege();
    }
  }, [galleryImageUploadStatus]);

  // below the code is for fetching
  useEffect(() => {
    const getAllAmenities = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getAmenitites,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setAmenitiesList(response.data.amenities);
        } else {
          alert(
            "Something went wrong while getting the Amenities Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the Amenities Details. Please try again later!!"
        );
      }
    };
    const getAllPlacementCompany = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getPlacementCompanies,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setPlacementCompaniesList(response.data.placementCompanies);
        } else {
          alert(
            "Something went wrong while getting the Placement Companies Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the Placement Companies Details. Please try again later!!"
        );
      }
    };
    const getAllAddress = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getAddress,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setAddressList(response.data.countries);
        } else {
          alert(
            "Something went wrong while getting the College Types Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the College Types Details. Please try again later!!"
        );
      }
    };
    const getAllCollegeType = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getCollegeTypes,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setCollegeTypeList(response.data.collegeTypes);
        } else {
          alert(
            "Something went wrong while getting the College Types Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the College Types Details. Please try again later!!"
        );
      }
    };
    const getAllUniversity = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getUniversities,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setUniversityList(response.data.universities);
        } else {
          alert(
            "Something went wrong while getting the University Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the University Details. Please try again later!!"
        );
      }
    };
    const getAllScholorship = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getScholorShips,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setScholorshipList(response.data.scholarships);
        } else {
          alert(
            "Something went wrong while getting the scholorship Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the scholorship Details. Please try again later!!"
        );
      }
    };
    const getAllProgram = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getPrograms,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setProgramList(response.data.programs);
        } else {
          alert(
            "Something went wrong while getting the Program Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the Program Details. Please try again later!!"
        );
      }
    };
    const getInstituteBrands = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getInstituteBrands,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setInstituteBrandList(response.data.instituteBrands);
        } else {
          alert(
            "Something went wrong while getting the insitute brand Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the insitute brand Details. Please try again later!!"
        );
      }
    };
    if (metadataFetching) {
      getAllUniversity();
      getAllCollegeType();
      getAllAddress();
      getAllPlacementCompany();
      getAllAmenities();
      getAllScholorship();
      getAllProgram();
      getInstituteBrands();
      setMetadataFetching(false);
    }
  }, [cookies, metadataFetching]);

  // below the code is for google map
  useEffect(() => {
    let url =
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      markerPosition.lat +
      "," +
      markerPosition.lng +
      "&key=" +
      config.googleMapKey;

    function updateState(type, state, setter, longName) {
      if (type) {
        setter(longName);
      }
    }

    fetch(url, {
      method: "get",
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.results.length > 0) {
          const data = res.results[0].address_components;
          for (let i = 0; i < data.length; i++) {
            const object = data[i];
            const types = object.types;

            updateState(
              types.includes("country"),
              country,
              setCountry,
              object.long_name
            );
            updateState(
              types.includes("administrative_area_level_1"),
              state,
              setState,
              object.long_name
            );
            updateState(
              types.includes("administrative_area_level_3"),
              district,
              setDistrict,
              object.long_name
            );
            updateState(
              types.includes("postal_code"),
              pincode,
              setPincode,
              object.long_name
            );
            updateState(
              // types.includes("sublocality") ||
              types.includes("sublocality"),
              // types.includes("sublocality_level_1"),
              area,
              setArea,
              object.long_name
            );
          }
        }
      });
  }, [markerPosition]);

  return (
    <>
      <CollegeTabs tabActive={1}>
        <form onSubmit={handleSubmit}>
          <Paper sx={{ p: 2, overflow: "scroll" }}>
            <Grid container spacing={2} sx={{ my: 2 }}>
              <Grid item md={6}>
                <TextField
                  type="text"
                  label="College Name"
                  value={collegeName.name}
                  fullWidth
                  onChange={handleCollegeNameChange}
                  error={collegeName.error}
                  helperText={collegeName.helperText}
                  autoComplete="off" // Disable autocomplete for previously entered values
                />
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  fullWidth
                  options={universityList.map((item) => item.universityName)}
                  onChange={(event, newValue) => {
                    const selectedUniversity = universityList.find(
                      (item) => item.universityName === newValue
                    );

                    if (selectedUniversity) {
                      const selectedUniversityId = selectedUniversity.id;
                      setUniversity({
                        id: selectedUniversityId,
                        error: false,
                        helperText: "",
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={university.error}
                      helperText={university.helperText}
                      {...params}
                      label="Select University"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  type="text"
                  label="OG URL"
                  fullWidth
                  disabled
                  value={ogUrl.url}
                  onChange={(e) => {
                    setOgUrl({
                      url: e.target.value,
                      error: false,
                      helperText: "",
                    });
                  }}
                  error={ogUrl.error}
                  helperText={ogUrl.helperText}
                />
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  fullWidth
                  options={programList.map((item) => item.programName)}
                  onChange={(event, newValue) => {
                    const selectedPrgram = programList.find(
                      (item) => item.programName === newValue
                    );

                    if (selectedPrgram) {
                      const selectedUniversityId = selectedPrgram.id;
                      setProgram({
                        id: selectedUniversityId,
                        error: false,
                        helperText: "",
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={program.error}
                      helperText={program.helperText}
                      {...params}
                      label="Select Program"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <CollegeCourse
              course={course}
              scholorshipList={scholorshipList}
              selectedProgram={program.id}
              setCourse={setCourse}
              courseTemplate={courseTemplate}
              courseNameError={courseNameError}
              courseNameHelperText={courseNameHelperText}
              setCourseNameError={setCourseNameError}
              courseFeeError={courseFeeError}
              courseBatchCapacityHelperText={courseBatchCapacityHelperText}
              setCourseBatchCapacityHelperText={
                setCourseBatchCapacityHelperText
              }
              specializationIdHelperText={specializationIdHelperText}
              setSpecializationIdHelperText={setSpecializationIdHelperText}
              specializationIdError={specializationIdError}
              setSpecializationIdError={setSpecializationIdError}
              yearError={yearError}
              yearHelperText={yearHelperText}
              setYearError={setYearError}
              setYearHelperText={setYearHelperText}
              monthError={monthError}
              monthHelperText={monthHelperText}
              setMonthError={setMonthError}
              setMonthHelperText={setMonthHelperText}
              courseFreeHelperText={courseFreeHelperText}
              setCourseFeeError={setCourseFeeError}
              setCourseBatchCapacityError={setCourseBatchCapacityError}
              setCourseFreeHelperText={setCourseFreeHelperText}
              courseBatchCapacityError={courseBatchCapacityError}
              setCourseNameHelperText={setCourseNameHelperText}
            />
            {/* add row amenities start from here  */}
            {amenities?.map((data, index) => (
              <Grid container spacing={1} key={index} sx={{ my: 2 }}>
                <Grid item md={3}>
                  <Autocomplete
                    fullWidth
                    options={amenitiesList?.map((item) => item.amenityName)}
                    onChange={(event, newValue) => {
                      const selectedAmenity = amenitiesList?.find(
                        (item) => item.amenityName === newValue
                      );

                      if (selectedAmenity) {
                        const selectedAmenityId = selectedAmenity.id;

                        handleChange(index, "amenityId", selectedAmenityId);
                      }
                      setAmenityIdError(false);
                      setAmenityIdHelperText("");
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={amenityIdError}
                        helperText={amenityIdHelperText}
                        {...params}
                        label="Select Amenity"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={7}>
                  <TextEditor
                    initialValue={data.amenityDescription}
                    onEditorChange={(value) =>
                      handleChange(index, "amenityDescription", value)
                    }
                    // rowIndex={index}
                  />
                </Grid>
                <Grid item md={1}>
                  <Button
                    variant="contained"
                    sx={{
                      background: "#405189",
                      borderRadius: 5,
                      border: 0,
                      color: "white",
                      height: 48,
                      padding: "0 20px",
                      boxShadow: "0 3px 5px 2px #405189",
                      transition: "background 0.3s",
                      "&:hover": {
                        background: "#405189",
                      },
                    }}
                    onClick={handleAddRow}
                  >
                    <AddIcon size="small" />
                  </Button>
                </Grid>
                {amenities.length > 1 && (
                  <Grid item md={1}>
                    <IconButton
                      color="red"
                      onClick={() => handleRemoveRow(index)}
                    >
                      <DeleteOutlineIcon fontSize="large" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            ))}
            {/* add row amenities end here  */}
            <Grid container spacing={2} sx={{ my: 2 }}>
              <Grid item md={3}>
                <Box
                  sx={{
                    border: collegeLogoError
                      ? "2px dashed red"
                      : "2px dashed #009688",
                    borderRadius: "15px",
                    padding: "20px",
                    backgroundColor: "#f5f5f5",
                    textAlign: "center",
                    cursor: "pointer",
                    transition: "border 0.3s",
                    ":hover": {
                      border: "2px dashed #005058",
                    },
                  }}
                >
                  <label htmlFor="logo-pic-college">
                    <input
                      id="logo-pic-college"
                      type="file"
                      accept=".png, .jpg, .jpeg, .webp"
                      style={{ display: "none" }}
                      onChange={(e) => fileValidation(e, "college logo")}
                    />
                    {collegeLogo ? (
                      <img
                        src={previewCollegeLogo}
                        alt="College Logo"
                        style={{
                          width: 200,
                          height: 200,
                          objectFit: "cover",
                          borderRadius: "10px",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        }}
                      />
                    ) : (
                      <>
                        <CloudUploadIcon
                          sx={{
                            fontSize: 80,
                            color: collegeLogoError ? "#d32f2f" : "#009688",
                          }}
                        />
                        <Typography
                          variant="h6"
                          sx={{
                            color: collegeLogoError ? "#d32f2f" : "#009688",
                            marginTop: "10px",
                          }}
                        >
                          {collegeLogoError
                            ? collegeLogoHelperText
                            : "Upload College Logo *"}
                        </Typography>
                        <Typography
                          variant="bodyparagraph"
                          sx={{ color: "#757575" }}
                        >
                          {collegeLogomaxFileSizeErr || "Max size: 1MB"}
                        </Typography>
                      </>
                    )}
                  </label>
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box
                  sx={{
                    border: bannerLogoError
                      ? "2px dashed #d32f2f"
                      : "2px dashed #009688",
                    borderRadius: "15px",
                    padding: "20px",
                    backgroundColor: "#f5f5f5",
                    textAlign: "center",
                    cursor: "pointer",
                    transition: "border 0.3s",
                    ":hover": {
                      border: "2px dashed #005058",
                    },
                    position: "relative", // Added for positioning the close icon
                  }}
                >
                  <label htmlFor="banner-image">
                    <input
                      id="banner-image"
                      type="file"
                      accept=".png, .jpg, .jpeg, .webp"
                      style={{ display: "none" }}
                      onChange={(e) => fileValidation(e, "banner image")}
                    />
                    {bannerLogo ? (
                      <>
                        <img
                          src={previewBannerLogo}
                          alt="College Logo"
                          style={{
                            width: 200,
                            height: 200,
                            objectFit: "cover",
                            borderRadius: "10px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                        />
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: 5,
                            right: 5,
                            backgroundColor: "#fff",
                          }}
                          onClick={() => {
                            // Handle removal logic here
                            setBannerLogo(null);
                            setPreviewBannerLogo(null);
                            setBannerPath(null);
                          }}
                        >
                          <CancelIcon sx={{ fontSize: 18, color: "#757575" }} />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <CloudUploadIcon
                          sx={{
                            fontSize: 80,
                            color: bannerLogoError ? "#d32f2f" : "#009688",
                          }}
                        />
                        <Typography
                          variant="h6"
                          sx={{
                            color: bannerLogoError ? "#d32f2f" : "#009688",
                            marginTop: "10px",
                          }}
                        >
                          {bannerLogoError
                            ? bannerLogoHelperText
                            : "Upload Banner Image"}
                        </Typography>
                        <Typography
                          variant="bodyparagraph"
                          sx={{ color: "#757575" }}
                        >
                          {collegeLogomaxFileSizeErr || "Max size: 1MB"}
                        </Typography>
                      </>
                    )}
                  </label>
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box
                  sx={{
                    border: broucherError
                      ? "2px dashed #d32f2f"
                      : "2px dashed #009688",
                    borderRadius: "15px",
                    padding: "20px",
                    backgroundColor: "#f5f5f5",
                    textAlign: "center",
                    cursor: "pointer",
                    transition: "border 0.3s",
                    ":hover": {
                      border: "2px dashed #005058",
                    },
                  }}
                >
                  <label htmlFor="Broucher-File-Upload">
                    <input
                      id="Broucher-File-Upload"
                      type="file"
                      accept=".pdf"
                      style={{ display: "none" }}
                      onChange={(e) => fileValidation(e, "brochure image")}
                    />
                    {broucherLogo ? (
                      <img
                        src={previewBroucherLogo}
                        alt="Broucher File"
                        style={{
                          width: 200,
                          height: 200,
                          objectFit: "cover",
                          borderRadius: "10px",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        }}
                      />
                    ) : (
                      <>
                        <CloudUploadIcon
                          sx={{
                            fontSize: 80,
                            color: broucherError ? "#d32f2f" : "#009688",
                          }}
                        />
                        <Typography
                          variant="h6"
                          sx={{
                            color: broucherError ? "#d32f2f" : "#009688",
                            marginTop: "10px",
                          }}
                        >
                          {broucherError
                            ? broucherHelperText
                            : "Upload Broucher"}
                        </Typography>
                        <Typography
                          variant="bodyparagraph"
                          sx={{ color: "#757575" }}
                        >
                          {collegeLogomaxFileSizeErr || "Max size: 5MB"}
                        </Typography>
                      </>
                    )}
                  </label>
                </Box>
              </Grid>
              {/* gallery image input  */}
              <Grid item md={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "#0077B6",
                      }}
                    >
                      Image Gallery
                    </Typography>
                    <Typography
                      variant="bodyregular"
                      sx={{
                        fontSize: "16px",
                        textAlign: "center",
                        color: "#333",
                      }}
                    >
                      You can upload multiple images (max 15)
                    </Typography>
                  </Box>
                  {previewImages.length !== 15 && (
                    <label htmlFor="image-gallery">
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="image-gallery"
                        multiple
                        type="file"
                        onClick={(ev) => (ev.target.value = "")}
                        onChange={handleImageGalleryUpload}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        sx={{
                          background: galleryImageError ? "#d32f2f" : "#405189",
                          borderRadius: 5,
                          border: 0,
                          color: "white",
                          height: 48,
                          padding: "0 30px",

                          boxShadow: "#405189",
                          "&:hover": {
                            //you want this to be the same as the backgroundColor above
                            backgroundColor: galleryImageError
                              ? "#d32f2f"
                              : "#405189",
                          },
                        }}
                      >
                        {galleryImageError
                          ? galleryImageHelperText
                          : "Upload Images *"}
                        {/* <AddAPhotoIcon sx={{ mr: 2 }} /> */}
                      </Button>
                    </label>
                  )}
                </Box>
              </Grid>
              {/* gallery image preview  */}
              <Grid item md={12}>
                <Box sx={{ overflowX: "auto", display: "flex" }}>
                  <ImageList
                    cols={3}
                    sx={{
                      flexWrap: "nowrap",
                    }}
                  >
                    {previewImages.map((items, idx) => (
                      <>
                        <ImageListItem
                          key={idx}
                          sx={{
                            margin: "8px",
                            cursor: "pointer",
                            transition: "transform 0.3s",
                            "&:hover": {
                              transform: "scale(1.05)",
                            },
                          }}
                        >
                          <img
                            src={items}
                            loading="lazy"
                            style={{
                              objectFit: "cover",
                              width: "100%",
                              height: "100%",
                            }}
                            alt="gallery-img"
                          />
                          <ImageListItemBar
                            sx={{
                              background:
                                "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                            }}
                            position="top"
                            actionPosition="right"
                            actionIcon={
                              <IconButton
                                onClick={() => deleteImageGallery(idx)}
                              >
                                <ClearIcon sx={{ color: "white" }}></ClearIcon>
                              </IconButton>
                            }
                          />
                        </ImageListItem>
                      </>
                    ))}
                  </ImageList>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Box sx={{ width: "98.5%" }}>
                  <LoadScript
                    googleMapsApiKey={config.googleMapKey}
                    libraries={libraries}
                  >
                    <GoogleMap
                      ref={mapRef}
                      onLoad={(map) => {
                        mapRef.current = map;
                        if (navigator.geolocation) {
                          navigator.geolocation.getCurrentPosition(
                            showPosition
                          );
                        }
                      }}
                      mapContainerStyle={{ height: "400px" }}
                      center={markerPosition}
                      zoom={17}
                      onDragEnd={mapCenterMoved}
                    >
                      <Marker
                        position={markerPosition}
                        draggable={true}
                        onDragEnd={onMarkerDragEnd}
                      />
                      <MapAutoComplete
                        onLoad={onAutoCompleteLoad}
                        onPlaceChanged={onPlaceChanged}
                      >
                        <input
                          type="text"
                          id="gmap-autocomplete"
                          placeholder="Search for your location"
                          style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `240px`,
                            height: `32px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: "absolute",
                            left: "50%",
                            marginLeft: "-120px",
                          }}
                          onKeyDown={(ev) => {
                            ev.key === "Enter" && ev.preventDefault();
                          }}
                        />
                      </MapAutoComplete>
                    </GoogleMap>
                  </LoadScript>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  fullWidth
                  freeSolo={true}
                  value={country}
                  options={addressList.map((item) => item.countryName)}
                  onChange={(e, newValue) => {
                    setCountry(newValue);
                    setCountryError(false);
                    setCountryHelperText("");
                  }}
                  error={countryError}
                  helperText={countryHelperText}
                  renderInput={(params) => (
                    <TextField
                      error={countryError}
                      helperText={countryHelperText}
                      {...params}
                      label="Country"
                      margin="dense"
                      variant="outlined"
                      onChange={(event, newValue) => {
                        if (!newValue) {
                          setCountry(event.target.value);
                        }
                      }}
                    />
                  )}
                />
                <Autocomplete
                  fullWidth
                  onChange={(e, newValue) => {
                    setState(newValue);
                    setStateError(false);
                    setStateHelperText("");
                  }}
                  error={stateError}
                  helperText={stateHelperText}
                  value={state}
                  options={(
                    addressList.find((item) => item.countryName === country)
                      ?.states || []
                  ).map((state) => state.stateName)}
                  freeSolo={true}
                  disabled={!country}
                  renderInput={(params) => (
                    <TextField
                      error={stateError}
                      margin="dense"
                      helperText={stateHelperText}
                      {...params}
                      label="State"
                      variant="outlined"
                      // value={state}
                      onChange={(event, newValue) => {
                        if (!newValue) {
                          setState(event.target.value);
                        }
                      }}
                    />
                  )}
                />
                <Autocomplete
                  fullWidth
                  options={districtList}
                  value={districtList.includes(district) ? district : ""}
                  disabled={!state}
                  freeSolo
                  onChange={(e, newValue) => {
                    setDistrict(newValue);
                    setDistrictError(false);
                    setDistrictHelperText("");
                  }}
                  error={districtError}
                  helperText={districtHelperText}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      error={districtError}
                      helperText={districtHelperText}
                      label={
                        districtList.includes(district)
                          ? "District"
                          : "Please Select District(City)"
                      }
                      variant="outlined"
                      onChange={(event, newValue) => {
                        if (!districtList.includes(newValue)) {
                          setDistrict(newValue);
                        }
                      }}
                    />
                  )}
                />
                <Autocomplete
                  fullWidth
                  value={pincodeList.includes(pincode) ? pincode : ""}
                  options={pincodeList}
                  disabled={!district}
                  freeSolo
                  onChange={(e, newValue) => {
                    setPincode(newValue);
                    setPincodeError(false);
                    setPincodeHelperText("");
                  }}
                  error={pincodeError}
                  helperText={pincodeHelperText}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label={
                        pincodeList.includes(pincode)
                          ? "Pincode"
                          : "Please Select Postal Code"
                      }
                      onChange={(event, newValue) => {
                        if (!pincodeList.includes(newValue)) {
                          setPincode(newValue);
                        }
                      }}
                      variant="outlined"
                    />
                  )}
                />
                <TextField
                  type="text"
                  label="Enter Area Name"
                  onChange={(e) => {
                    setArea(e.target.value);
                    setAreaError(false);
                    setAreaHelperText("");
                  }}
                  value={area}
                  margin="dense"
                  error={areaError}
                  helperText={areaHelperText}
                  fullWidth
                />
                <TextField
                  label="Established Year"
                  value={establishedYear.year}
                  onChange={(e) => {
                    setEstablishedYear({
                      year: e.target.value,
                      error: false,
                      helperText: "",
                    });
                  }}
                  autoComplete="off"
                  error={establishedYear.error}
                  helperText={establishedYear.helperText}
                  inputProps={{
                    maxLength: 4,
                  }}
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ my: 2 }}>
              <Grid item md={8}>
                <Autocomplete
                  multiple
                  id="scholarship-autocomplete"
                  options={scholorshipList}
                  getOptionLabel={(option) => option.scholarshipName}
                  value={scholorshipList.filter((company) =>
                    scholarship.ids.includes(company.id.toString())
                  )}
                  onChange={handleScholarshipChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Scholarship"
                      error={scholarship.error}
                      helperText={scholarship.helperText}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        label={option.scholarshipName}
                        {...getTagProps({ index })}
                        onDelete={() => handleRemoveScholarship(option)}
                      />
                    ))
                  }
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  type="text"
                  value={scholorShipCutOff.cutOff}
                  error={scholorShipCutOff.error}
                  helperText={scholorShipCutOff.helperText}
                  label="ScholorShip Cut Off"
                  onChange={(e) =>
                    setScholorShipCutOff({
                      cutOff: e.target.value,
                      error: false,
                      helperText: "",
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item md={4}>
                <Autocomplete
                  fullWidth
                  options={collegeTypeList}
                  getOptionLabel={(option) => option.collegeType} // Adjust this based on your data structure
                  onChange={(e, newValue) => {
                    // Handle the change event and update the state with the selected college type ID
                    setCollegeType({
                      id: newValue ? newValue.id : null,
                      error: false,
                      helperText: "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      value={
                        (
                          collegeTypeList.find(
                            (ct) => ct.id === collegeType.id
                          ) || {}
                        ).collegeType || ""
                      }
                      error={collegeType.error}
                      helperText={collegeType.helperText}
                      {...params}
                      label="Select College Type"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Autocomplete
                  fullWidth
                  options={instituteBrandList.map((item) => item.brandName)}
                  onChange={(event, newValue) => {
                    const selectedBrandName = instituteBrandList.find(
                      (item) => item.brandName === newValue
                    );

                    if (selectedBrandName) {
                      const selectedBrandNameId = selectedBrandName.id;
                      setInstituteBrand({
                        id: selectedBrandNameId,
                        error: false,
                        helperText: "",
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={instituteBrand.error}
                      helperText={instituteBrand.helperText}
                      {...params}
                      label="Select Institute Brand"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item md={12}>
                <Autocomplete
                  multiple
                  id="placement-companies-autocomplete"
                  options={placementCompaniesList}
                  getOptionLabel={(option) => option.companyName}
                  value={placementCompaniesList.filter((company) =>
                    placementCompany.ids.includes(company.id.toString())
                  )}
                  onChange={handlePlacementChange}
                  renderInput={(params) => (
                    <TextField
                      error={placementCompany.error}
                      helperText={placementCompany.helperText}
                      {...params}
                      label="Select Placement Companies"
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        label={option.companyName}
                        {...getTagProps({ index })}
                        onDelete={() => handleRemovepc(option)}
                      />
                    ))
                  }
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  type="text"
                  value={emails}
                  autoComplete="off"
                  onChange={(e) => setEmails(e.target.value)}
                  label="Enter Emails comma Separate Each Email"
                  placeholder="Enter Emails comma Separate Each Email"
                  fullWidth
                />
              </Grid>

              <Grid item md={12}>
                <TextField
                  type="url"
                  value={website}
                  autoComplete="off"
                  onChange={(e) => setWebsite(e.target.value)}
                  label="Website"
                  placeholder="Enter College Website URL"
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  type="text"
                  value={contactNumbers}
                  label="Contact Numbers"
                  autoComplete="off"
                  onChange={(e) => setContactNumbers(e.target.value)}
                  placeholder="Enter Contact Numbers comma Separate Each Number"
                  fullWidth
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  type="text"
                  value={trustName}
                  label="Trust Name"
                  autoComplete="off"
                  onChange={(e) => setTrustName(e.target.value)}
                  placeholder="Enter College Trust Name"
                  fullWidth
                />
              </Grid>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    College Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={collegeCategory}
                    label="College Category"
                    onChange={(e) => setCollegeCategory(e.target.value)}
                  >
                    <MenuItem value="co-ed">co-ed</MenuItem>
                    <MenuItem value="Boys">Boys</MenuItem>
                    <MenuItem value="Girls">Girls</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <input
                  type="file"
                  accept="video/*"
                  multiple={true}
                  ref={fileInputRef}
                  style={{ display: "none" }} // hide the default file input
                  onChange={handleFileChange}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<CloudUploadIcon />}
                  onClick={handleClick}
                >
                  Upload Video
                </Button>
                <Typography variant="body2" color="textSecondary">
                  Select one or multiple video files.
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Box sx={{ display: "flex", overflowX: "auto" }}>
                  {videoFiles.map((file, index) => (
                    <Box key={index} style={{ margin: "0 5px" }}>
                      <video width="300" height="300" controls>
                        <source
                          src={URL.createObjectURL(file)}
                          type="video/mp4"
                        />
                      </video>
                      <br />
                      <Button
                        type="button"
                        onClick={() => handleRemoveFile(index)}
                      >
                        Remove
                      </Button>
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item md={6}>
                <TextField
                  type="text"
                  value={youtubeUrl.urls}
                  label="YouTube Video URL"
                  variant="outlined"
                  error={youtubeUrl.error}
                  helperText={youtubeUrl.helperText}
                  fullWidth
                  onChange={(e) => {
                    setYoutubeUrl({
                      urls: e.target.value,
                      error: false,
                      helperText: "",
                    });
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <Button variant="contained" onClick={handleAddVideo}>
                  Add Video
                </Button>
              </Grid>
              <Grid item md={12}>
                <List>
                  {videoLinks.map((video, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={video.mediaFilePath} />
                      <IconButton onClick={() => handleRemoveVideo(index)}>
                        <CloseIcon />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              {/* TextEditor components */}
              {Object.keys(summary).map(
                (key) =>
                  key !== "metaDescription" &&
                  key !== "schemaScript" &&
                  key !== "keywords" && (
                    <>
                      <Grid item md={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="subtitle1">
                            {key === "admission"
                              ? "Admission Summary"
                              : key === "placementCompany"
                              ? "Placement Company Summary"
                              : key === "cutOff"
                              ? "Cut Off Summary"
                              : key === "scholarship"
                              ? "Scholarship Summary"
                              : key === "courseFee"
                              ? "Course Fee Summary"
                              : key === "result"
                              ? "College Result Summary"
                              : key === "qna"
                              ? "Frequently Asked Questions"
                              : key === "description"
                              ? "College Full Description"
                              : null}
                          </Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {summaryImage ? (
                              <>
                                <input
                                  type="text"
                                  value={summaryImageUrl}
                                  sx={{ width: "100px" }}
                                  disabled
                                />
                                <IconButton
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      summaryImageUrl
                                    );
                                    toast.success("Copied", {
                                      position: "top-center",
                                      autoClose: 5000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                      theme: "colored",
                                    });
                                  }}
                                >
                                  <ContentCopyIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    setSummaryImageUrl(null);
                                    setSummaryImage(null);
                                  }}
                                >
                                  <RestartAltIcon />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <label
                                  htmlFor="file-upload"
                                  style={{ cursor: "pointer" }}
                                >
                                  <Button
                                    variant="contained"
                                    component="span"
                                    startIcon={<CloudUploadIcon />}
                                  >
                                    Upload Image
                                  </Button>
                                </label>
                                <input
                                  id="file-upload"
                                  type="file"
                                  accept=".png, .jpg, .jpeg, .webp"
                                  onChange={(e) => fileValidation(e, "summary")}
                                  style={{ display: "none" }}
                                />
                              </>
                            )}
                          </Box>
                        </Box>
                        <TextEditor
                          key={key}
                          initialValue={summary[key]}
                          onEditorChange={(e) => handleTextChange(key, e)}
                        />
                      </Grid>
                    </>
                  )
              )}
              <Grid item md={12}>
                {/* Schema Script TextField */}
                <TextField
                  id="schema-script-static"
                  label="Enter Schema Script"
                  onChange={(e) =>
                    handleTextChange("schemaScript", e.target.value)
                  }
                  value={summary.schemaScript}
                  multiline
                  rows={5} // Adjust the number of rows as needed
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                {/* Meta Description TextField */}
                <TextField
                  id="meta-description-static"
                  label="Enter Meta Description"
                  onChange={handleMetaDescriptionChange}
                  value={summary.metaDescription.content}
                  error={summary.metaDescription.error}
                  helperText={summary.metaDescription.helperText}
                  multiline
                  rows={5} // Adjust the number of rows as needed
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                {/* Keywords TextField */}
                <TextField
                  id="keywords-multiline-static"
                  label="Enter Keywords"
                  onChange={(e) => handleTextChange("keywords", e.target.value)}
                  value={summary.keywords}
                  multiline
                  rows={5}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item md={12} sx={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={saveLoading}
                  sx={{
                    background: "#405189",
                    borderRadius: 5,
                    border: 0,
                    color: "white",
                    height: 48,
                    padding: "0 30px",
                    boxShadow: "0 3px 5px 2px #405189",
                    "&:hover": {
                      //you want this to be the same as the backgroundColor above
                      backgroundColor: "#405189",
                    },
                  }}
                >
                  Submit
                  {saveLoading && <CircularProgress size={20} />}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </CollegeTabs>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Remove Video</DialogTitle>
        <DialogContent>
          Are you sure you want to remove this video?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleCloseDialog} color="error">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default College;
